import { useEffect } from 'react';

import { useIsInvoiceNumberRegisteredQuery } from '../generated/graphql';

/**
 * This hook is for checking invoice number against the backend, and then run a function once the data arrives.
 */
export default function useRunIfInvoiceNumberExists(
  invoiceNumber: string,
  fn: (isExisted: boolean) => void
): void {
  const { data } = useIsInvoiceNumberRegisteredQuery({
    skip: !invoiceNumber,
    variables: { invoiceNumber: invoiceNumber }
  });

  useEffect(() => {
    if (data) {
      fn(!!data.isInvoiceNumberRegistered);
    }
  });
}
