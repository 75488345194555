import { PaymentMethod } from '../generated/graphql';

interface CalTotalPayableInvoiceParams {
  policyFee: number;
  totalPremium: number;
  tax: number;
  currency: string; // TODO: update type or delete the whole function, use data from backend
}

interface CalTotalPayableInvoiceParamsWithPaymentPlan {
  policyFee: number;
  totalPremium: number;
  tax: number;
  currency: string;
  totalNumberOfPayments: number;
  transactionFee: number;
}

/**
 * Calculate total payable and credit card fees (if applicable).
 * note: Unlike the backend where Graphql guarantees input to be positive, the frontend
 * needs to do the check before calling this method.
 */
const calTotalPayable = (
  { policyFee, totalPremium, tax, currency }: CalTotalPayableInvoiceParams,
  method: PaymentMethod
): {
  creditCardFee: number;
  creditCardFeeRate: number;
  totalPayable: number;
} => {
  const invoiceSumWithoutTax = policyFee + totalPremium;
  const invoiceSum = invoiceSumWithoutTax + tax;

  switch (method) {
    case PaymentMethod.CreditCard: {
      //TODO this should not be determined based on currency
      const country = currency === 'CAD' ? 'CA' : 'US';
      let CREDIT_CARD_FEES_RATE;
      if (country === 'CA') {
        CREDIT_CARD_FEES_RATE = 0.025; // 2.5%
      } else {
        CREDIT_CARD_FEES_RATE = 0;
      }
      const creditCardFee = +(
        invoiceSumWithoutTax * CREDIT_CARD_FEES_RATE
      ).toFixed(2);
      const totalPayable = +(invoiceSum + creditCardFee).toFixed(2);
      return {
        creditCardFee,
        creditCardFeeRate: CREDIT_CARD_FEES_RATE,
        totalPayable
      };
    }
    default: {
      const totalPayable = +invoiceSum.toFixed(2);
      return {
        creditCardFee: 0,
        creditCardFeeRate: 0,
        totalPayable
      };
    }
  }
};

const calTotalPayableWithPaymentPlan = (
  {
    policyFee,
    totalPremium,
    tax,
    currency,
    totalNumberOfPayments,
    transactionFee
  }: CalTotalPayableInvoiceParamsWithPaymentPlan,
  method: PaymentMethod
): {
  creditCardFee: number;
  creditCardFeeRate: number;
  totalPayable: number;
} => {
  const invoiceSumWithoutTax = policyFee + totalPremium;
  const invoiceSum = invoiceSumWithoutTax + tax;

  switch (method) {
    case PaymentMethod.CreditCard: {
      //TODO this should not be determined based on currency
      const {
        transactionFee: adjustedTransactionFee,
        CREDIT_CARD_FEES_RATE
      } = (() => {
        const country = currency === 'CAD' ? 'CA' : 'US';
        if (country === 'CA') {
          // TODO: configurable in the future
          return { CREDIT_CARD_FEES_RATE: 0.029, transactionFee };
        } else {
          return { CREDIT_CARD_FEES_RATE: 0, transactionFee: 0 };
        }
      })();

      const creditCardFee = +(
        invoiceSumWithoutTax * CREDIT_CARD_FEES_RATE +
        totalNumberOfPayments * adjustedTransactionFee
      ).toFixed(2);
      const totalPayable = +(invoiceSum + creditCardFee).toFixed(2);
      return {
        creditCardFee,
        creditCardFeeRate: CREDIT_CARD_FEES_RATE,
        totalPayable
      };
    }
    default: {
      const totalPayable = +invoiceSum.toFixed(2);
      return {
        creditCardFee: 0,
        creditCardFeeRate: 0,
        totalPayable
      };
    }
  }
};

export { calTotalPayable, calTotalPayableWithPaymentPlan };
