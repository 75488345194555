import React from 'react';

import { PaymentMethod } from '../../../generated/graphql';

interface ProceedButtonProps {
  paymentMethod: PaymentMethod;
  onClick: () => void;
}

const BUTTON_CLASS =
  'bg-tertiary py-2 px-6 text-base text-white font-bold rounded-md';
const BUTTON_LABEL = 'PROCEED';

const ProceedButton: React.FC<ProceedButtonProps> = ({
  paymentMethod,
  onClick
}) => {
  switch (paymentMethod) {
    case PaymentMethod.CreditCard: // fall-through
      return (
        <button
          className={BUTTON_CLASS}
          onClick={onClick}
          data-testid="ProceedButton"
        >
          {BUTTON_LABEL}
        </button>
      );
    default:
      throw new Error(
        `cannot create submit button because payment method is not supported: ${PaymentMethod[paymentMethod]}`
      );
  }
};

export default ProceedButton;
