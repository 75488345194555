import './customerPortal.css';

import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

import { DocumentDownloadLink } from '../../generated/graphql';

interface DownloadPortalParams {
  documentDownloadLink: DocumentDownloadLink;
}

const DownloadPortal: React.FC<DownloadPortalParams> = ({
  documentDownloadLink
}) => {
  const [goBack, setGoBack] = useState<boolean>(false);
  if (goBack) {
    return <Redirect push to="/customer-portal" />;
  }
  return (
    <div className="grid grid-cols-2 pl-10 place-items-start gap-4 pt-10 ">
      <div className="row-start-1 text-xl uppercase font-bold">documents</div>
      <div className="row-start-2 font-bold">Policy Document</div>
      <div className="row-start-2">
        <button
          className="download-button"
          onClick={() => {
            window.open(documentDownloadLink.policyDocument);
          }}
        >
          Download
        </button>
      </div>

      <div className="row-start-3 font-bold">Payment Receipt</div>
      <div className="row-start-3">
        <button
          className="download-button"
          onClick={() => {
            window.open(documentDownloadLink.PaymentReceipt);
          }}
        >
          Download
        </button>
      </div>

      <div className="row-start-4 font-bold">T&C Document</div>
      <div className="row-start-4">
        <button
          className="download-button"
          onClick={() => {
            window.open(documentDownloadLink.T_CDocument);
          }}
        >
          Download
        </button>
      </div>

      <div className="row-start-5 col-span-2 pt-5">
        <button
          onClick={() => {
            setGoBack(true);
          }}
          className="bg-orange py-2 px-6 text-white font-bold rounded-md uppercase"
        >
          Back
        </button>
      </div>
    </div>
  );
};
export default DownloadPortal;
