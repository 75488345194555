/**
 * Checks if the user is logged in.
 * @returns {boolean} Returns true if the user is logged in, false otherwise.
 */
export const checkIsLoggedIn = (): boolean => {
  return localStorage.getItem('com.foxden.t&c.isLoggedIn') === 'true';
};

/**
 * Stores the login status in the local storage.
 */
export const storeLogin = (): void => {
  localStorage.setItem('com.foxden.t&c.isLoggedIn', 'true');
};

/**
 * Removes the 'com.foxden.t&c.isLoggedIn' item from the localStorage.
 */
export const storeLogout = (): void => {
  localStorage.removeItem('com.foxden.t&c.isLoggedIn');
};

/**
 * Checks if the user is signed in from a session storage.
 * @returns {boolean} Returns true if the user is signed in, false otherwise.
 */
export const checkIsSessionSignedin = (): boolean => {
  return sessionStorage.getItem('com.foxden.t&c.session.signedin') === 'true';
};

/**
 * Stores the signin status in the session storage.
 */
export const storeSessionSignin = (): void => {
  sessionStorage.setItem('com.foxden.t&c.session.signedin', 'true');
};

/**
 * Removes the 'com.foxden.t&c.session.signedin' item from the sessionStorage.
 */
export const storeSessionSignout = (): void => {
  sessionStorage.removeItem('com.foxden.t&c.session.signedin');
};
