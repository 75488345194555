import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';

import { useAuth } from '../../auth/AuthProvider';
import foxquiltBrand from '../../images/brand.png';
import foxquiltLogo from '../../images/logo.png';
import Signout from '../Signout';
import UserSetting from './UserSetting';

type HeaderProps = {
  usedWithRoutes?: boolean;
};

const Header: React.FC<HeaderProps> = ({ usedWithRoutes }) => {
  const {
    isAuthenticated: isAuthAuthenticated,
    user: authUser,
    isLoading: isAuthLoading,
    logout: authLogout
  } = useAuth();
  const {
    isAuthenticated: isAuth0Authenticated,
    user: auth0User,
    isLoading: isAuth0Loading,
    logout: auth0Logout
  } = useAuth0();
  const isAuthenticated = isAuthAuthenticated || isAuth0Authenticated;
  const isLoading = isAuthLoading
    ? isAuthLoading // auth service has higher priority
    : !isAuthenticated && isAuth0Loading; // if Auth service is not loading, isAuthenticated should be true or false. If it is false, should use isAuth0Loading value
  const user = isAuthAuthenticated ? authUser : auth0User;
  const name = isAuthAuthenticated
    ? authUser?.name?.givenName
    : user?.nickname
    ? user.nickname
    : user?.given_name;
  const logout = isAuthAuthenticated ? authLogout : auth0Logout;
  const { pathname } = useLocation();
  const title = ((pathname: string) => {
    if (pathname === '/customer-portal') {
      return 'Customer Portal';
    } else if (pathname.includes('customer-portal')) {
      return 'Policy Information';
    } else {
      return null;
    }
  })(pathname);
  const headerRef = useRef(null);
  const [helperShowing, setHelperShowing] = useState(false);
  const [sticky, setSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      window.scrollY == 0 ? setSticky(false) : setSticky(true);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  if (isLoading && usedWithRoutes) {
    return <div data-testid="isloadingdiv"></div>;
  }

  const pathName = window.location.pathname;
  const isCustomerPortal = pathName.includes('customer-portal');

  return (
    <header
      className="sticky z-50 w-full top-0 p-0 m-0"
      data-testid="Header"
      ref={headerRef}
    >
      {usedWithRoutes && isCustomerPortal ? <Signout /> : null}
      <div
        className={`${
          sticky && 'shadow-md'
        } w-full h-20 px-6 md:px-20 bg-white`}
      >
        <nav className="flex justify-between items-center w-full h-full">
          <div className="flex h-16">
            <div className="flex w-full h-full">
              <div className="pt-1 w-9 lg:w-11">
                <img className="w-full" src={foxquiltLogo} alt="company-logo" />
              </div>
              <div className="ml-2 w-20 lg:w-24 flex items-center">
                <img
                  className="w-full"
                  src={foxquiltBrand}
                  alt="company-logo"
                />
              </div>
            </div>
          </div>
          <div
            id="helpMenu"
            onMouseLeave={() => setHelperShowing(false)}
            onMouseEnter={() => setHelperShowing(true)}
          >
            <div className="h-20 flex items-center">
              <span className="whitespace-nowrap tracking-wider font-medium text-sm sm:text-base">
                Need help? | <br className="block sm:hidden" />
                <a href="tel:+1-888-878-4588">1-888-878-4588</a>
              </span>
            </div>
            {helperShowing ? (
              <div className="absolute max-w-xs top-20 right-0 min-w-fit rounded-bl-xl text-xs sm:text-base">
                <div className="p-4 bg-gray-300 rounded-bl-xl shadow-md">
                  {[
                    { href: 'mailto:hello@foxquilt.com', text: 'Live Chat' },
                    {
                      href: 'mailto:hello@foxquilt.com',
                      text: 'hello@foxquilt.com'
                    },
                    { href: 'tel:+1-888-878-4588', text: '1-888-878-4588' }
                  ].map(({ href, text }, index) => (
                    <a
                      key={index}
                      href={href}
                      className="block mb-3 font-medium"
                    >
                      {text}
                    </a>
                  ))}
                  <p className="mb-3">
                    Monday to Friday:
                    <br />
                    8:00 AM – 8:00 PM (EST)
                    <br />
                    5:00 AM – 5:00 PM (PST)
                  </p>
                  <p className="mb-3">
                    After hours and Weekends: We&apos;ll try our very best to
                    support you during these times when possible
                  </p>
                </div>
              </div>
            ) : null}
          </div>
        </nav>
      </div>

      <div>
        <div className="float-right">
          {isAuthenticated ? (
            <UserSetting name={name ?? ''} logout={logout} />
          ) : null}
        </div>
        {title ? (
          <div className="float-left p-2 pl-6 text-xl bg-secondary rounded-br border-none font-bold text-white w-60 h-12 text-left">
            {title}
          </div>
        ) : null}
      </div>
    </header>
  );
};

export default Header;
