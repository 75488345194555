import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import createAuth0Client, { Auth0Client } from '@auth0/auth0-spa-js';
import React, { ReactNode, useEffect, useState } from 'react';

import { useAuth } from '../../auth/AuthProvider';
import getEnv from '../../utils/getEnv';
import Loading from '../Loading';

const {
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_GRAPHQL_URL
} = getEnv();

interface ApolloProviderProps {
  children: ReactNode;
}

const ApolloApp = ({ children }: ApolloProviderProps): JSX.Element => {
  const {
    isAuthenticated,
    quoteId,
    invoiceId,
    isLoading: isAuthLoading
  } = useAuth();
  const [auth0Client, setAuth0Client] = useState<Auth0Client | undefined>(
    undefined
  );
  const [auth0Token, setAuth0Token] = useState<string | undefined>(undefined);
  const [isAuth0InitLoading, setAuth0InitLoading] = useState(true);

  useEffect(() => {
    const getAuth0Client = async () => {
      const client = await createAuth0Client({
        domain: REACT_APP_AUTH0_DOMAIN,
        client_id: REACT_APP_AUTH0_CLIENT_ID
      });
      setAuth0Client(client);
      if (!auth0Token) {
        try {
          const token = await client.getTokenSilently();
          setAuth0Token(token);
        } catch (e) {
          // no opps
        }
      }
      setAuth0InitLoading(false);
    };
    getAuth0Client();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Set URI for all Apollo GraphQL requests (backend api) */
  let httpLink = new HttpLink({ uri: REACT_APP_GRAPHQL_URL });
  if (isAuthenticated) {
    httpLink = new HttpLink({
      ...{
        uri: REACT_APP_GRAPHQL_URL,
        credentials: 'include'
      },
      ...{ headers: { quoteId, invoiceId } }
    });
  }

  /* Create Apollo Link to supply token with either
   * in-memory ref or auth0 req'ed token or redirect (built into
   * getTokenSilently
   */
  const withTokenLink = setContext(async (_, prevContext) => {
    let token = auth0Token;
    if (!auth0Token) {
      token = await auth0Client?.getTokenSilently();
      setAuth0Token(token);
    }
    return { ...prevContext, auth0Token: token };
  });

  /* Create Apollo Link to supply token in auth header with every gql request */
  const authLink = setContext((_, { headers, auth0Token, ...context }) => ({
    ...context,
    headers: {
      ...headers,
      ...(auth0Token ? { authorization: `Bearer ${auth0Token}` } : {})
    }
  }));

  /* Create Apollo Link array to pass to Apollo Client */
  const link = isAuthenticated
    ? ApolloLink.from([httpLink])
    : ApolloLink.from([withTokenLink, authLink, httpLink]);

  /* Create Apollo Client */
  const apolloClient = new ApolloClient({
    link,
    cache: new InMemoryCache()
  });

  if (isAuthLoading || isAuth0InitLoading) {
    return (
      <div className="grid  h-full w-full text-quartary">
        <div className="flex">
          <Loading />
        </div>
      </div>
    );
  }

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default ApolloApp;
