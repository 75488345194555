import './customerPortal.css';

import { ApolloError } from '@apollo/client';
import React from 'react';

import { MutationSendCoiArgs, SendCoiMutation } from '../../generated/graphql';

interface CoiParams {
  policyId: string;
  country: string;
  sendCoi: (sendCoiArgs: { variables: MutationSendCoiArgs }) => void;
  coiData?: SendCoiMutation | null;
  coiError?: ApolloError;
}

const Coi: React.FC<CoiParams> = ({
  country,
  sendCoi,
  coiData,
  policyId,
  coiError
}) => {
  const coiBox = (
    <div className="py-5">
      <form
        onSubmit={(e: React.SyntheticEvent) => {
          e.preventDefault();
          const target = e.target as typeof e.target & {
            name: { value: string };
            city: { value: string };
            street: { value: string };
            province: { value: string };
            postalCode: { value: string };
          };
          const {
            name: { value: name },
            city: { value: city },
            street: { value: street },
            province: { value: province },
            postalCode: { value: postalCode }
          } = target;
          const additionalInsured = {
            name,
            address: { street, city, province, postalCode }
          };
          sendCoi({
            variables: {
              policyFoxdenId: policyId,
              country: country,
              additionalInsured
            }
          });
        }}
      >
        <table>
          <tbody className="text-base lowercase">
            <tr>
              <td>Name:</td>
              <td>
                <input type="name" className="border-2" name="name" />
              </td>
            </tr>
            <tr>
              <td>Street:</td>
              <td>
                <input type="street" className="border-2" name="street" />
              </td>
            </tr>
            <tr>
              <td>City:</td>
              <td>
                <input type="city" className="border-2" name="city" />
              </td>
            </tr>
            <tr>
              <td>Province:</td>
              <td>
                <input type="province" className="border-2" name="province" />
              </td>
            </tr>
            <tr>
              <td>PostalCode</td>
              <td>
                <input
                  type="postalCode"
                  className="border-2"
                  name="postalCode"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <button type="submit" value="Submit" className="border-2">
          send coi
        </button>
      </form>
    </div>
  );
  const sendCoiResult = (() => {
    if (coiData) {
      return <div>send COI success</div>;
    } else if (coiError) {
      return <div>send COI fail</div>;
    } else {
      return null;
    }
  })();
  return (
    <div className=" pl-10 place-items-start gap-4 pt-10 ">
      <div className="row-start-1 text-xl uppercase font-bold">
        COI{coiBox}
        {sendCoiResult}
      </div>
    </div>
  );
};
export default Coi;
