/* eslint-disable no-console */
import { Auth0Provider } from '@auth0/auth0-react';
import {
  VersionControllerClient,
  WorkflowVersion
} from '@foxden/version-controller-client';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { urlJoin } from 'url-join-ts';

import { PaymentPublicClient } from '../backend-client/payment-public-backend';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Loading from '../components/Loading';
import getEnv from '../utils/getEnv';
import { AuthContext } from './authContext';
import { useAuth } from './AuthProvider';

const {
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_PUBLIC_URL,
  REACT_APP_VERSION_CONTROLLER_GRAPHQL_URL
} = getEnv();

/**
 * Note: Auth0 needs explicit access to the history, so we need to get the history
 * with the useHistory hook. Here we're typing Auth0 react-router-dom together.
 *
 * Copied from:
 * https://auth0.com/blog/complete-guide-to-react-user-authentication/
 */
const Auth0ProviderWithHistory: React.FC = ({ children }) => {
  // Use the useLocation hook to access the current location
  const location = useLocation();
  const history = useHistory();
  const { isAuthenticated: isAuthAuthenticated, user } = useAuth();

  const queryParams = new URLSearchParams(location.search);
  const quoteId = queryParams.get('quote') ?? undefined;

  const pathname = location?.pathname?.trim();
  const regex = /.*terms\//i;
  const tempQuoteId = regex.test(pathname)
    ? pathname.replace(regex, '')
    : undefined;

  const [versionData, setVersionData] = useState<WorkflowVersion>();
  const [versionDataError, setVersionDataError] = useState();
  const [emailAddress, setEmailAddress] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [
    getEmailAddressByQuoteIdError,
    setGetEmailAddressByQuoteIdError
  ] = useState();

  useEffect(() => {
    if (quoteId !== undefined) {
      setIsLoading(true);

      const versionControllerClient = new VersionControllerClient(
        REACT_APP_VERSION_CONTROLLER_GRAPHQL_URL
      );
      versionControllerClient
        .getVersionFromQuote(quoteId)
        .then((data) => {
          setVersionData(data);
          setIsLoading(false);
        })
        .catch((err) => {
          setVersionDataError(err);
          setIsLoading(false);
        });
    }
  }, [quoteId]);

  useEffect(() => {
    if (quoteId !== undefined) {
      setIsLoading(true);

      const paymentPublicClient = new PaymentPublicClient(
        versionData?.paymentBackendVersion
      );

      paymentPublicClient
        .getEmailAddressByQuoteId(quoteId)
        .then((data) => {
          setEmailAddress(data);
          setIsLoading(false);
        })
        .catch((err) => {
          setGetEmailAddressByQuoteIdError(err);
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteId, versionData]);

  if (tempQuoteId && !tempQuoteId.includes('?quote=')) {
    window.location.replace(
      urlJoin(window.location.origin, REACT_APP_PUBLIC_URL) +
        `/terms/?quote=${tempQuoteId}`
    );
    return null;
  }

  if (isLoading) {
    return (
      <div className="grid grid-rows-main h-full w-full text-quartary">
        <Header usedWithRoutes={false} />
        <div className="flex">
          <Loading />
        </div>
        <Footer />
      </div>
    );
  }

  if (versionDataError) {
    throw Error('Failed when tring to get version data');
  }

  if (getEmailAddressByQuoteIdError) {
    throw Error('Failed when tring to get email address by quoteId');
  }

  const domain = REACT_APP_AUTH0_DOMAIN;
  const clientId = REACT_APP_AUTH0_CLIENT_ID;

  const origin = urlJoin(window.location.origin, REACT_APP_PUBLIC_URL) + '/';

  if (isAuthAuthenticated) {
    return (
      <AuthContext.Provider value={{ login_hint: user?.email }}>
        {children}
      </AuthContext.Provider>
    );
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      login_hint={emailAddress}
      redirectUri={origin}
      onRedirectCallback={(appState) => {
        const revisedReturnTo = appState?.returnTo
          ? appState?.returnTo.replace(`${REACT_APP_PUBLIC_URL}`, '')
          : window.location.pathname.replace(`${REACT_APP_PUBLIC_URL}`, '');
        history.replace(revisedReturnTo || window.location.pathname);
      }}
    >
      <AuthContext.Provider value={{ login_hint: emailAddress }}>
        {children}
      </AuthContext.Provider>
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
