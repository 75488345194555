import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AbbrevCanadaProvince: any;
  Answers: any;
  CanadaProvince: "Alberta" | "British Columbia" | "Manitoba" | "New Brunswick" | "Newfoundland and Labrador" | "Northwest Territories" | "Nova Scotia" | "Nunavut" | "Ontario" | "Prince Edward Island" | "Quebec" | "Saskatchewan" | "Yukon";
  CanadaTimeZone: any;
  ChangedQuoteObject: any;
  Date: string;
  EmailAddress: string;
  JSONObject: any;
  NonEmptyString: string;
  NonNegativeFloat: number;
  NonNegativeInt: number;
  ObjectID: string;
  PolicyDescription: "CGL" | "E&O" | "D&O" | "Crime" | "Cyber Liability" | "Legal Expense" | "Medical Malpractice";
  PositiveFloat: number;
  PostalCode: string;
  URL: any;
  USTimeZone: any;
  UUID: any;
};


export type Address = {
  __typename?: 'Address';
  street: Scalars['String'];
  city: Scalars['String'];
  region: Scalars['CanadaProvince'];
  postalCode: Scalars['PostalCode'];
};

export type AddressInput = {
  street: Scalars['String'];
  city: Scalars['String'];
  region: Scalars['CanadaProvince'];
  postalCode: Scalars['PostalCode'];
};


export type BusinessAddressChange = {
  __typename?: 'BusinessAddressChange';
  newAddress: UnderwritingAddress;
};

export type CalChangedQuoteQuoteInput = {
  objectId: Scalars['ObjectID'];
  results: CalChangedQuoteQuoteResultsInput;
};

export type CalChangedQuoteQuoteResultsInput = {
  calculations: MunichCalculationsInput;
  coverageStartDate: Scalars['Date'];
  coverageExpiryDate: Scalars['Date'];
};



export type CancellationQuoteResponse = {
  __typename?: 'CancellationQuoteResponse';
  quoteNumber: Scalars['String'];
  quoteExpireDate: Scalars['Date'];
  results: CancellationQuoteResults;
};

export type CancellationQuoteResults = {
  __typename?: 'CancellationQuoteResults';
  prevQuoteObjectId: Scalars['ObjectID'];
  /** Prorated premiums */
  calculations: MunichProratedCalculations;
  coverageStartDate: Scalars['Date'];
  coverageExpiryDate: Scalars['Date'];
};

export type CancellationSuccess = {
  __typename?: 'CancellationSuccess';
  result: Scalars['Boolean'];
};

export enum CancellationTrigger {
  Carrier = 'carrier',
  Client = 'client'
}


export type ChangedQuoteResponse = {
  __typename?: 'ChangedQuoteResponse';
  quoteNumber: Scalars['String'];
  quoteExpireDate: Scalars['Date'];
  results: ChangedQuoteResults;
};

export type ChangedQuoteResults = {
  __typename?: 'ChangedQuoteResults';
  prevQuoteObjectId: Scalars['ObjectID'];
  /** The quote result for the input answers */
  curQuoteResults: QuoteResults;
  /** Prorated premiums */
  calculations: MunichProratedCalculations;
  coverageStartDate: Scalars['Date'];
  coverageExpiryDate: Scalars['Date'];
};

export type ChargeSuccess = {
  __typename?: 'ChargeSuccess';
  getInvoiceDeciderResult: GetInvoiceSuccess;
  userPaymentPlanOptionDeciderResult: GetUserPaymentOptionSuccess;
};

export enum ChildCoverageType {
  /** Commercial General Liability */
  Cgl = 'CGL',
  /** Errors & Omissions */
  Eo = 'EO',
  /** Limited Pollution */
  LimitedPollution = 'LimitedPollution',
  /** Unmanned Aircraft */
  UnmannedAircraft = 'UnmannedAircraft',
  /** Unscheduled Business Personal PropertyP */
  UnscheduledBpp = 'UnscheduledBPP',
  /** Hardware / Software */
  Hwsw = 'HWSW',
  /** Lock Key Business Items */
  LockKeyBusinessItems = 'LockKeyBusinessItems',
  /** Unscheduled Contractors */
  UnscheduledContractors = 'UnscheduledContractors',
  /** Unscheduled hardware/software */
  UnscheduledHwsw = 'UnscheduledHWSW',
  /** Unscheduled Small Tools */
  UnscheduledSmallTools = 'UnscheduledSmallTools',
  /** Lock Key Contractors */
  LockKeyContractors = 'LockKeyContractors',
  /** Photo Video Endorsement */
  PhotoVideoEndor = 'PhotoVideoEndor',
  /** Physical Loss */
  PhysicalLoss = 'PhysicalLoss',
  /** Valuable Papers */
  ValuablePapers = 'ValuablePapers'
}

export type CoiAdditionalInsuredInput = {
  address: UnderwritingAddressInput;
  name: Scalars['NonEmptyString'];
};

export type CoverageLimit = {
  __typename?: 'CoverageLimit';
  coverage: ChildCoverageType;
  oldLimit?: Maybe<Scalars['NonNegativeFloat']>;
  newLimit?: Maybe<Scalars['NonNegativeFloat']>;
};

export type CoverageUpdateChange = {
  __typename?: 'CoverageUpdateChange';
  coverage: ParentCoverageType;
  limits: Array<CoverageLimit>;
};

export type CreatePaymentInput = {
  invoiceId: Scalars['ObjectID'];
  method: PaymentMethod;
  provider: Provider;
};

export type CreatePaymentSessionInput = {
  invoiceId: Scalars['ObjectID'];
  paymentPlanId: Scalars['NonNegativeFloat'];
  method: PaymentMethod;
  useCardForRenewals: Scalars['Boolean'];
};

export enum CreateRefundableSequenceErrorKind {
  /** No Valid PaymentResult under this Policy */
  NoValidPaymentResult = 'NoValidPaymentResult'
}

/** Currencies that are supported by Foxden. */
export enum Currency {
  Cad = 'CAD',
  Usd = 'USD'
}


export type DocumentDownloadLink = {
  __typename?: 'DocumentDownloadLink';
  policyDocument: Scalars['String'];
  COI: Scalars['String'];
  PaymentReceipt: Scalars['String'];
  T_CDocument: Scalars['String'];
};

export type DocumentInformation = {
  __typename?: 'DocumentInformation';
  documentDownloadLink: DocumentDownloadLink;
  policyDetails: PolicyDetail;
};


export type EndorsementChange = BusinessAddressChange | CoverageUpdateChange | MailingAddressChange;

export enum FlowKind {
  /** This means initial flow, not endorsement */
  Initial = 'Initial',
  /** This means in endorsement flow, we need to refund clients */
  RefundFullPay = 'RefundFullPay',
  /** This means in endorsement flow, we need to refund clients */
  RefundInstallment = 'RefundInstallment',
  /** This means in endorsement flow, we need to waive */
  Waive = 'Waive',
  /** This means in endorsement flow, we need to charge clients */
  Charge = 'Charge',
  Cancellation = 'Cancellation'
}

export type FoxdenError = {
  __typename?: 'FoxdenError';
  fileName: Scalars['String'];
  message: Scalars['String'];
};

export type GenerateOhioEndorsementSigningUrlResult = {
  __typename?: 'GenerateOhioEndorsementSigningURLResult';
  envelopeId: Scalars['UUID'];
  signingURL: Scalars['URL'];
};

export type GetFlowKindSuccess = {
  __typename?: 'GetFlowKindSuccess';
  flowKind: FlowKind;
  isPaid: Scalars['Boolean'];
  isExpired: Scalars['Boolean'];
  policyRemoval: PolicyRemoval;
  policyFoxdenId: Scalars['String'];
};

/** The error responses for getInvoice. Instead of revealing the data we just return a flag. */
export enum GetInvoiceErrorKind {
  /** This means invoiceId doesn't match our database. Record doesn't exist. */
  NotFound = 'NotFound'
}

export type GetInvoiceResults = GetInvoiceSuccess | FoxdenError;

export type GetInvoiceSuccess = {
  __typename?: 'GetInvoiceSuccess';
  invoiceInfo: InvoiceInfo;
  isPaid: Scalars['Boolean'];
  isExpired: Scalars['Boolean'];
  policyFoxdenId?: Maybe<Scalars['String']>;
  timeZone: Scalars['CanadaTimeZone'];
};

export type GetPolicyTaxServiceFeeRateResults = GetPolicyTaxServiceFeeRateSuccess | FoxdenError;

export type GetPolicyTaxServiceFeeRateSuccess = {
  __typename?: 'GetPolicyTaxServiceFeeRateSuccess';
  surchargeRate: Scalars['Float'];
  taxRate: Scalars['Float'];
  serviceFeeRate: Scalars['Float'];
  policyFeeRate: Scalars['Float'];
};

export type GetUniqueValueUnderPolicyNumberResults = FoxdenError | UniqueValueUnderPolicyNumber;

export type GetUserPaymentOptionSuccess = {
  __typename?: 'GetUserPaymentOptionSuccess';
  userPaymentPlanOption: Array<UserPaymentPlanOption>;
};

export enum IdType {
  Claim = 'Claim',
  Invoice = 'Invoice',
  Policy = 'Policy',
  Quote = 'Quote',
  Transaction = 'Transaction'
}

export type InitialSuccess = {
  __typename?: 'InitialSuccess';
  getInvoiceDeciderResult: GetInvoiceSuccess;
  userPaymentPlanOptionDeciderResult: GetUserPaymentOptionSuccess;
};

export enum InstallmentFrequency {
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
  Annual = 'Annual'
}

/** TODO: Update currency type, todo item is to replace currency with real credit card fee in backend */
export type InvoiceClient = {
  __typename?: 'InvoiceClient';
  address: Address;
};

export type InvoiceClientInput = {
  namedInsured: Scalars['NonEmptyString'];
  name: NameInput;
  contactNumber: Scalars['String'];
  email: Scalars['EmailAddress'];
  address: AddressInput;
};

export type InvoiceInfo = {
  __typename?: 'InvoiceInfo';
  invoiceNumber: Scalars['String'];
  invoiceDate: Scalars['Date'];
  quoteNumber: Scalars['String'];
  totalPremium: Scalars['Float'];
  glSurcharge: Scalars['Float'];
  imSurcharge: Scalars['Float'];
  surcharge: Scalars['Float'];
  tax: Scalars['Float'];
  policyFee: Scalars['Float'];
  currency: Scalars['String'];
  client?: Maybe<InvoiceClient>;
};

export type InvoiceInfoInput = {
  invoiceNumber: Scalars['String'];
  invoiceDate: Scalars['Date'];
  quoteNumber: Scalars['String'];
  totalPremium: Scalars['Float'];
  glSurcharge: Scalars['Float'];
  imSurcharge: Scalars['Float'];
  surcharge: Scalars['Float'];
  tax: Scalars['Float'];
  glPolicyFeeTax: Scalars['Float'];
  imPolicyFeeTax: Scalars['Float'];
  policyFeeTax: Scalars['Float'];
  glPolicyFee: Scalars['Float'];
  imPolicyFee: Scalars['Float'];
  policyFee: Scalars['Float'];
  client: InvoiceClientInput;
  policyDetails: InvoicePolicyDetailsInput;
};

export type InvoicePolicyDetailsInput = {
  effectiveDate: Scalars['Date'];
  description: Array<Scalars['PolicyDescription']>;
  notes: Scalars['String'];
};

export type IsExpired = {
  __typename?: 'IsExpired';
  isExpired: Scalars['Boolean'];
};

export type IsPaid = {
  __typename?: 'IsPaid';
  policyNumber: Scalars['String'];
};


export type MailingAddressChange = {
  __typename?: 'MailingAddressChange';
  newAddress: UnderwritingAddress;
};

export type MonerisData = {
  __typename?: 'MonerisData';
  hpp_id: Scalars['String'];
  ticket: Scalars['String'];
};

export type MonerisPreloadData = {
  __typename?: 'MonerisPreloadData';
  hpp_id: Scalars['String'];
  ticket: Scalars['String'];
};

export type MunichCalculations = {
  __typename?: 'MunichCalculations';
  GL: MunichGlCalculations;
  IM: MunichImCalculations;
  totalPremium: Scalars['NonNegativeFloat'];
};

export type MunichCalculationsInput = {
  GL: MunichGlCalculationsInput;
  IM: MunichImCalculationsInput;
  totalPremium: Scalars['NonNegativeFloat'];
};

export type MunichGlAggregations = {
  __typename?: 'MunichGLAggregations';
  /** This is the premium for the required coverage. */
  basePremium: Scalars['NonNegativeFloat'];
  /** This is the premium including all additional coverages. */
  totalPremium: Scalars['NonNegativeFloat'];
  pollutionPremium: Scalars['NonNegativeFloat'];
  unmannedAircraftPremium: Scalars['NonNegativeFloat'];
  eoPremium: Scalars['NonNegativeFloat'];
  abuseAndMolestationPremium: Scalars['NonNegativeFloat'];
};

export type MunichGlAggregationsInput = {
  /** This is the premium for the required coverage. */
  basePremium: Scalars['NonNegativeFloat'];
  /** This is the premium including all additional coverages. */
  totalPremium: Scalars['NonNegativeFloat'];
  pollutionPremium: Scalars['NonNegativeFloat'];
  unmannedAircraftPremium: Scalars['NonNegativeFloat'];
  eoPremium: Scalars['NonNegativeFloat'];
  abuseAndMolestationPremium: Scalars['NonNegativeFloat'];
};

export type MunichGlCalculations = {
  __typename?: 'MunichGLCalculations';
  labels: MunichGlRateLabels;
  aggregations: MunichGlAggregations;
};

export type MunichGlCalculationsInput = {
  labels: MunichGlRateLabelsInput;
  aggregations: MunichGlAggregationsInput;
};

export type MunichGlProratedAggregations = {
  __typename?: 'MunichGLProratedAggregations';
  /** This is the premium for the required coverage. */
  basePremium: Scalars['Float'];
  /** This is the premium including all additional coverages. */
  totalPremium: Scalars['Float'];
  pollutionPremium: Scalars['Float'];
  unmannedAircraftPremium: Scalars['Float'];
  eoPremium: Scalars['Float'];
  abuseAndMolestationPremium: Scalars['Float'];
};

export type MunichGlProratedCalculations = {
  __typename?: 'MunichGLProratedCalculations';
  labels: MunichGlProratedRateLabels;
  aggregations: MunichGlProratedAggregations;
};

export type MunichGlProratedRateLabels = {
  __typename?: 'MunichGLProratedRateLabels';
  cglPremPremium: Scalars['NonNegativeFloat'];
  cglProdPremium: Scalars['NonNegativeFloat'];
  cglMiniAdditionalPremium: Scalars['NonNegativeFloat'];
  cglAdditionalLimitsPremium: Scalars['NonNegativeFloat'];
};

export type MunichGlRateLabels = {
  __typename?: 'MunichGLRateLabels';
  /** TODO: types can be more specific */
  cglPremLabels: Scalars['JSONObject'];
  cglProdLabels: Scalars['JSONObject'];
  cglMeetMinLabels: Scalars['JSONObject'];
  cglAdditionalLimitsLabels: Scalars['JSONObject'];
  pollutionLiabLabels: Scalars['JSONObject'];
  unmannedAircraftLabels: Scalars['JSONObject'];
  eoLabels: Scalars['JSONObject'];
  abuseMolestationLabels: Scalars['JSONObject'];
  /** We can remove these fields or keep these, they are already in the sub labels */
  cglPremPremium: Scalars['NonNegativeFloat'];
  cglProdPremium: Scalars['NonNegativeFloat'];
  cglMiniAdditionalPremium: Scalars['NonNegativeFloat'];
  cglAdditionalLimitsPremium: Scalars['NonNegativeFloat'];
};

export type MunichGlRateLabelsInput = {
  /** TODO: types can be more specific */
  cglPremLabels: Scalars['JSONObject'];
  cglProdLabels: Scalars['JSONObject'];
  cglMeetMinLabels: Scalars['JSONObject'];
  cglAdditionalLimitsLabels: Scalars['JSONObject'];
  pollutionLiabLabels: Scalars['JSONObject'];
  unmannedAircraftLabels: Scalars['JSONObject'];
  eoLabels: Scalars['JSONObject'];
  abuseMolestationLabels: Scalars['JSONObject'];
  /** We can remove these fields or keep these, they are already in the sub labels */
  cglPremPremium: Scalars['NonNegativeFloat'];
  cglProdPremium: Scalars['NonNegativeFloat'];
  cglMiniAdditionalPremium: Scalars['NonNegativeFloat'];
  cglAdditionalLimitsPremium: Scalars['NonNegativeFloat'];
};

export type MunichImAggregations = {
  __typename?: 'MunichIMAggregations';
  /** This is the premium for the required coverage. */
  basePremium: Scalars['NonNegativeFloat'];
  /** It's the total premium (adjusted with minimum premium) - premiums for additional coverages */
  adjustBasePremium: Scalars['NonNegativeFloat'];
  /** This is the premium including all additional coverages. */
  totalPremium: Scalars['NonNegativeFloat'];
  /** Additional Endorsements */
  photographyAndVideographyPremium: Scalars['NonNegativeFloat'];
  /** Additional Endorsements */
  valualePapersPremium: Scalars['NonNegativeFloat'];
  /** Additional Endorsements */
  physicalLossPremium: Scalars['NonNegativeFloat'];
};

export type MunichImAggregationsInput = {
  /** This is the premium for the required coverage. */
  basePremium: Scalars['NonNegativeFloat'];
  /** It's the total premium (adjusted with minimum premium) - premiums for additional coverages */
  adjustBasePremium: Scalars['NonNegativeFloat'];
  /** This is the premium including all additional coverages. */
  totalPremium: Scalars['NonNegativeFloat'];
  /** Additional Endorsements */
  photographyAndVideographyPremium: Scalars['NonNegativeFloat'];
  /** Additional Endorsements */
  valualePapersPremium: Scalars['NonNegativeFloat'];
  /** Additional Endorsements */
  physicalLossPremium: Scalars['NonNegativeFloat'];
};

export type MunichImCalculations = {
  __typename?: 'MunichIMCalculations';
  labels: MunichImRateLabels;
  aggregations: MunichImAggregations;
};

export type MunichImCalculationsInput = {
  labels: MunichImRateLabelsInput;
  aggregations: MunichImAggregationsInput;
};

export type MunichImProratedAggregations = {
  __typename?: 'MunichIMProratedAggregations';
  /** It's the total premium (adjusted with minimum premium) - premiums for additional coverages */
  adjustBasePremium: Scalars['Float'];
  /** This is the premium for the required coverage. */
  basePremium: Scalars['Float'];
  /** This is the premium including all additional coverages. */
  totalPremium: Scalars['Float'];
  /** Additional Endorsements */
  photographyAndVideographyPremium: Scalars['Float'];
  /** Additional Endorsements */
  valualePapersPremium: Scalars['Float'];
  /** Additional Endorsements */
  physicalLossPremium: Scalars['Float'];
};

export type MunichImProratedCalculations = {
  __typename?: 'MunichIMProratedCalculations';
  labels: MunichImProratedRateLabels;
  aggregations: MunichImProratedAggregations;
};

export type MunichImProratedRateLabels = {
  __typename?: 'MunichIMProratedRateLabels';
  miniPremium: Scalars['NonNegativeFloat'];
  miniAddPremium: Scalars['NonNegativeFloat'];
  /** For contractors */
  unscheduledContractorsEquipmentPremium: Scalars['NonNegativeFloat'];
  /** For contractors */
  unscheduledHardwareAndSoftwarePremium: Scalars['NonNegativeFloat'];
  /** For contractors */
  unscheduledSmallToolsPremium: Scalars['NonNegativeFloat'];
  /** For contractors */
  contractorsLockAndKeyPremium: Scalars['NonNegativeFloat'];
  /** For non-contractors */
  unscheduledBPPPremium: Scalars['NonNegativeFloat'];
  /** For non-contractors */
  hardwareAndSoftwarePremium: Scalars['NonNegativeFloat'];
  /** For non-contractors */
  lockAndKeyPremium: Scalars['NonNegativeFloat'];
};

export type MunichImRateLabels = {
  __typename?: 'MunichIMRateLabels';
  unscheduledContractorsEquipmentLebels: Scalars['JSONObject'];
  unscheduledHardwareAndSoftwareLabels: Scalars['JSONObject'];
  unscheduledSmallToolsLebels: Scalars['JSONObject'];
  contractorsLockAndKeyLabels: Scalars['JSONObject'];
  unscheduledBPPLabels: Scalars['JSONObject'];
  hardwareAndSoftwareLabels: Scalars['JSONObject'];
  lockAndKeyLabels: Scalars['JSONObject'];
  photographyAndVideographyLebels: Scalars['JSONObject'];
  valualePapersLebels: Scalars['JSONObject'];
  physicalLossLabels: Scalars['JSONObject'];
  miniPremium: Scalars['NonNegativeFloat'];
  miniAddPremium: Scalars['NonNegativeFloat'];
  /** For contractors */
  unscheduledContractorsEquipmentPremium: Scalars['NonNegativeFloat'];
  /** For contractors */
  unscheduledHardwareAndSoftwarePremium: Scalars['NonNegativeFloat'];
  /** For contractors */
  unscheduledSmallToolsPremium: Scalars['NonNegativeFloat'];
  /** For contractors */
  contractorsLockAndKeyPremium: Scalars['NonNegativeFloat'];
  /** For non-contractors */
  unscheduledBPPPremium: Scalars['NonNegativeFloat'];
  /** For non-contractors */
  hardwareAndSoftwarePremium: Scalars['NonNegativeFloat'];
  /** For non-contractors */
  lockAndKeyPremium: Scalars['NonNegativeFloat'];
};

export type MunichImRateLabelsInput = {
  unscheduledContractorsEquipmentLebels: Scalars['JSONObject'];
  unscheduledHardwareAndSoftwareLabels: Scalars['JSONObject'];
  unscheduledSmallToolsLebels: Scalars['JSONObject'];
  contractorsLockAndKeyLabels: Scalars['JSONObject'];
  unscheduledBPPLabels: Scalars['JSONObject'];
  hardwareAndSoftwareLabels: Scalars['JSONObject'];
  lockAndKeyLabels: Scalars['JSONObject'];
  photographyAndVideographyLebels: Scalars['JSONObject'];
  valualePapersLebels: Scalars['JSONObject'];
  physicalLossLabels: Scalars['JSONObject'];
  miniPremium: Scalars['NonNegativeFloat'];
  miniAddPremium: Scalars['NonNegativeFloat'];
  /** For contractors */
  unscheduledContractorsEquipmentPremium: Scalars['NonNegativeFloat'];
  /** For contractors */
  unscheduledHardwareAndSoftwarePremium: Scalars['NonNegativeFloat'];
  /** For contractors */
  unscheduledSmallToolsPremium: Scalars['NonNegativeFloat'];
  /** For contractors */
  contractorsLockAndKeyPremium: Scalars['NonNegativeFloat'];
  /** For non-contractors */
  unscheduledBPPPremium: Scalars['NonNegativeFloat'];
  /** For non-contractors */
  hardwareAndSoftwarePremium: Scalars['NonNegativeFloat'];
  /** For non-contractors */
  lockAndKeyPremium: Scalars['NonNegativeFloat'];
};

export type MunichProratedCalculations = {
  __typename?: 'MunichProratedCalculations';
  GL: MunichGlProratedCalculations;
  IM: MunichImProratedCalculations;
  prorateFactor: Scalars['NonNegativeFloat'];
  newCharge: Scalars['Float'];
  totalPremium: Scalars['NonNegativeFloat'];
};

export type Mutation = {
  __typename?: 'Mutation';
  noOp?: Maybe<Scalars['Boolean']>;
  sendCOI: Scalars['Boolean'];
  storeInvoiceInfo: Scalars['ObjectID'];
  monerisCreatePayment: MonerisPreloadData;
  createPaymentSession: SessionData;
  completeRefund: Scalars['Boolean'];
  cancelationRefund: Scalars['Boolean'];
  saveInvoice: Scalars['URL'];
  generateSigningURL: Scalars['URL'];
  generateOhioEndorsementSigningURL: GenerateOhioEndorsementSigningUrlResult;
};


export type MutationSendCoiArgs = {
  country: Scalars['String'];
  policyFoxdenId: Scalars['String'];
  additionalInsured: CoiAdditionalInsuredInput;
};


export type MutationStoreInvoiceInfoArgs = {
  invoice: InvoiceInfoInput;
};


export type MutationMonerisCreatePaymentArgs = {
  paymentInfo: CreatePaymentInput;
};


export type MutationCreatePaymentSessionArgs = {
  paymentInfo: CreatePaymentSessionInput;
};


export type MutationCompleteRefundArgs = {
  creditMemoObjectId: Scalars['ObjectID'];
};


export type MutationCancelationRefundArgs = {
  invoiceId: Scalars['ObjectID'];
};


export type MutationSaveInvoiceArgs = {
  quoteId: Scalars['ObjectID'];
};


export type MutationGenerateSigningUrlArgs = {
  quoteId: Scalars['ObjectID'];
};


export type MutationGenerateOhioEndorsementSigningUrlArgs = {
  jsonFileName: Scalars['String'];
  policyObjectId: Scalars['ObjectID'];
  profession: Scalars['String'];
  quote: Scalars['ChangedQuoteObject'];
  signerClientId: Scalars['String'];
  signerEmail: Scalars['EmailAddress'];
  timeZone: Scalars['CanadaTimeZone'];
};

export type Name = {
  __typename?: 'Name';
  first: Scalars['String'];
  middle: Scalars['String'];
  last: Scalars['String'];
};

export type NameInput = {
  first: Scalars['String'];
  middle: Scalars['String'];
  last: Scalars['String'];
};





export enum ParentCoverageType {
  /** Commercial General Liability */
  Cgl = 'CGL',
  /** Inland Marine */
  Im = 'IM'
}

export enum PaymentMethod {
  CreditCard = 'CreditCard'
}

export type PaymentRouteDeciderResults = IsPaid | IsExpired | InitialSuccess | RefundFullPaySuccess | RefundInstallmentSuccess | WaiveSuccess | ChargeSuccess | CancellationSuccess | FoxdenError;

export enum PolicyCancelReason {
  /** Business Closed */
  BusinessClosed = 'BusinessClosed',
  /** Found Better Pricing Elsewhere */
  FoundBetterPricingElsewhere = 'FoundBetterPricingElsewhere',
  /** Insurance Is No Longer Required */
  InsuranceIsNoLongerRequired = 'InsuranceIsNoLongerRequired',
  /** Business Sold */
  BusinessSold = 'BusinessSold',
  /** Other */
  Other = 'Other'
}


export type PolicyDetail = {
  __typename?: 'PolicyDetail';
  timeZone: Scalars['CanadaTimeZone'];
  namedInsured: Scalars['String'];
  mailingAddress: UnderwritingAddress;
  insuredLocation: UnderwritingAddress;
  policyStartDate: Scalars['Date'];
  policyEndDate: Scalars['Date'];
  operations: Array<Scalars['String']>;
  country: Scalars['String'];
};

export type PolicyRemoval = {
  __typename?: 'PolicyRemoval';
  trigger?: Maybe<Scalars['String']>;
  removal?: Maybe<Scalars['String']>;
  stateOrProvince?: Maybe<Scalars['String']>;
  premium?: Maybe<Premium>;
  waivedPremium?: Maybe<WaivedPremium>;
};



export type Premium = {
  __typename?: 'Premium';
  imRefundPremium: Scalars['Float'];
  glRefundPremium: Scalars['Float'];
  imEarnedPremium: Scalars['Float'];
  glEarnedPremium: Scalars['Float'];
};

export enum Provider {
  Moneris = 'Moneris',
  Stripe = 'Stripe'
}

export type Query = {
  __typename?: 'Query';
  noOp?: Maybe<Scalars['Boolean']>;
  getAuth0UserPolicies: Array<Scalars['String']>;
  getDocumentInformation: DocumentInformation;
  /** This API only returns the invoice only if there's an associated complete payment. */
  getInvoice: GetInvoiceResults;
  isInvoiceNumberRegistered: Scalars['Boolean'];
  paymentRouteDecider: PaymentRouteDeciderResults;
  getPolicyTaxServiceFeeRate: GetPolicyTaxServiceFeeRateResults;
  getUserPaymentPlanOption: Array<UserPaymentPlanOption>;
  getUserPaymentPlanOptionByQuoteId: Array<UserPaymentPlanOptionByQuoteId>;
  getEmailAddressByQuoteId: Scalars['String'];
  getUniqueValueUnderPolicyNumber: GetUniqueValueUnderPolicyNumberResults;
  generateTnCDocument?: Maybe<Scalars['String']>;
  getApplicationbyQuoteId: GetApplicationbyQuoteIdResponse;
};


export type QueryGetDocumentInformationArgs = {
  policyId: Scalars['String'];
};


export type QueryGetInvoiceArgs = {
  invoiceId: Scalars['ObjectID'];
  placeholder?: Maybe<Scalars['JSONObject']>;
};


export type QueryIsInvoiceNumberRegisteredArgs = {
  invoiceNumber: Scalars['String'];
};


export type QueryPaymentRouteDeciderArgs = {
  invoiceId: Scalars['ObjectID'];
};


export type QueryGetPolicyTaxServiceFeeRateArgs = {
  provinceOrState: Scalars['String'];
  country: Scalars['String'];
};


export type QueryGetUserPaymentPlanOptionArgs = {
  InvoiceId: Scalars['ObjectID'];
};


export type QueryGetUserPaymentPlanOptionByQuoteIdArgs = {
  quoteId: Scalars['ObjectID'];
};


export type QueryGetEmailAddressByQuoteIdArgs = {
  quoteId: Scalars['ObjectID'];
};


export type QueryGetUniqueValueUnderPolicyNumberArgs = {
  policyNumber: Scalars['String'];
};


export type QueryGenerateTnCDocumentArgs = {
  answers: Scalars['JSONObject'];
  jsonFileName: Scalars['String'];
  country: Scalars['String'];
  rating: Scalars['JSONObject'];
  carrierPartner: Scalars['String'];
};


export type QueryGetApplicationbyQuoteIdArgs = {
  quoteId: Scalars['ObjectID'];
};

export type QuoteResponse = {
  __typename?: 'QuoteResponse';
  quoteId: Scalars['ObjectID'];
  premium: Scalars['Float'];
  quoteNumber: Scalars['String'];
};

/** This isn't used in the Graphql API; used for generating Typescript type. */
export type QuoteResults = {
  __typename?: 'QuoteResults';
  calculations: MunichCalculations;
  ratingInput: Scalars['JSONObject'];
  coverageStartDate: Scalars['Date'];
  coverageExpiryDate: Scalars['Date'];
};

export type QuoteResultsInput = {
  calculations: MunichCalculationsInput;
  ratingInput: Scalars['JSONObject'];
  coverageStartDate: Scalars['Date'];
  coverageExpiryDate: Scalars['Date'];
};

export type RefundFullPaySuccess = {
  __typename?: 'RefundFullPaySuccess';
  refundedSequence: Array<RefundableSequenceItem>;
};

export type RefundInstallmentSuccess = {
  __typename?: 'RefundInstallmentSuccess';
  result: Scalars['Boolean'];
};

export type RefundableSequenceItem = {
  __typename?: 'RefundableSequenceItem';
  timestamp: Scalars['Date'];
  paymentIntentId: Scalars['String'];
  invoiceId: Scalars['ObjectID'];
  refundableAmount: Scalars['NonNegativeFloat'];
};

export type SessionData = StripeData | MonerisData;

export type StripeData = {
  __typename?: 'StripeData';
  session_id: Scalars['String'];
  stripePlatformAccount: Scalars['String'];
};


export enum UsCancellationMethod {
  /** Flat */
  Flat = 'Flat',
  /** Short Rate */
  ShortRate = 'ShortRate',
  /** Pro Rata */
  ProRata = 'ProRata'
}

export enum UsPolicyCancelReason {
  /** Not Taken */
  NotTaken = 'NotTaken',
  /** Requested by Insured */
  RequestByInsured = 'RequestByInsured',
  /** Rewritten */
  Rewritten = 'Rewritten',
  /** Other */
  Other = 'Other'
}

export enum UsRequestDistribution {
  Insured = 'Insured',
  Mortgagee = 'Mortgagee',
  Company = 'Company',
  LossPayee = 'LossPayee',
  Lienholder = 'Lienholder',
  FinanceCompany = 'FinanceCompany',
  LenderLossPayable = 'LenderLossPayable'
}



export type UnderwritingAddress = {
  __typename?: 'UnderwritingAddress';
  street: Scalars['String'];
  city: Scalars['String'];
  province: Scalars['AbbrevCanadaProvince'];
  postalCode: Scalars['PostalCode'];
};

export type UnderwritingAddressInput = {
  street: Scalars['String'];
  city: Scalars['String'];
  province: Scalars['AbbrevCanadaProvince'];
  postalCode: Scalars['PostalCode'];
};

export type UniqueValueUnderPolicyNumber = {
  __typename?: 'UniqueValueUnderPolicyNumber';
  subscriptionScheduleId: Scalars['String'];
};

/** Needs to send this URL to customer for signing the Terms & Conditions before the endorsement can take effect. */
export type UpdatePolicyProceedToTerms = {
  __typename?: 'UpdatePolicyProceedToTerms';
  /** The ObjectID for the temporary policy object created by this operation. */
  insertedObjectId: Scalars['ObjectID'];
  /** URL to Docusign page. */
  url: Scalars['URL'];
  /** Does this endorsement possibly require payment or refund */
  isInvolvePayment: Scalars['Boolean'];
  /** The new charge */
  newCharge?: Maybe<Scalars['Float']>;
};

export type UserPaymentPlanOption = {
  __typename?: 'UserPaymentPlanOption';
  paymentPlanId: Scalars['NonNegativeFloat'];
  paymentPlan: Scalars['String'];
  invoiceNumber: Scalars['String'];
  invoiceDate: Scalars['Date'];
  quoteNumber: Scalars['String'];
  totalPremiumAmount: Scalars['NonNegativeFloat'];
  totalSurcharge: Scalars['NonNegativeFloat'];
  totalTaxes: Scalars['NonNegativeFloat'];
  totalPolicyFees: Scalars['NonNegativeFloat'];
  totalServiceFees: Scalars['NonNegativeFloat'];
  totalPayable: Scalars['NonNegativeFloat'];
  policyInceptionDate: Scalars['Date'];
  downPayment: Scalars['NonNegativeFloat'];
  numberOfInstallments: Scalars['NonNegativeFloat'];
  installmentFrequency: Scalars['String'];
  installmentAmount: Scalars['NonNegativeFloat'];
  paymentSchedule: Array<Maybe<Scalars['Date']>>;
};

export type UserPaymentPlanOptionByQuoteId = {
  __typename?: 'UserPaymentPlanOptionByQuoteID';
  paymentPlanId: Scalars['NonNegativeFloat'];
  paymentPlan: Scalars['String'];
  totalPremiumAmount: Scalars['NonNegativeFloat'];
  totalTaxes: Scalars['NonNegativeFloat'];
  totalPolicyFees: Scalars['NonNegativeFloat'];
  totalServiceFees: Scalars['NonNegativeFloat'];
  totalPayable: Scalars['NonNegativeFloat'];
  downPayment: Scalars['NonNegativeFloat'];
  numberOfInstallments: Scalars['NonNegativeFloat'];
  installmentFrequency: Scalars['String'];
  installmentAmount: Scalars['NonNegativeFloat'];
  surcharge: Scalars['Float'];
};

export type ValidateEndorsementResult = {
  __typename?: 'ValidateEndorsementResult';
  changes: Array<EndorsementChange>;
  effectiveDate: Scalars['Date'];
  isInvolvePayment: Scalars['Boolean'];
};

export type WaiveSuccess = {
  __typename?: 'WaiveSuccess';
  result: Scalars['Boolean'];
};

export type WaivedPremium = {
  __typename?: 'WaivedPremium';
  IMForUs?: Maybe<Scalars['Float']>;
  GLForUs?: Maybe<Scalars['Float']>;
  Canada?: Maybe<Scalars['Float']>;
};

export type GetApplicationbyQuoteIdResponse = {
  __typename?: 'getApplicationbyQuoteIdResponse';
  country: Scalars['String'];
  state: Scalars['String'];
};

export type GetEmailAddressByQuoteIdQueryVariables = Exact<{
  quoteId: Scalars['ObjectID'];
}>;


export type GetEmailAddressByQuoteIdQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getEmailAddressByQuoteId'>
);


export const GetEmailAddressByQuoteIdDocument = gql`
    query getEmailAddressByQuoteId($quoteId: ObjectID!) {
  getEmailAddressByQuoteId(quoteId: $quoteId)
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    getEmailAddressByQuoteId(variables: GetEmailAddressByQuoteIdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetEmailAddressByQuoteIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetEmailAddressByQuoteIdQuery>(GetEmailAddressByQuoteIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getEmailAddressByQuoteId', 'query');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;