import { ApolloError } from '@apollo/client';
import React from 'react';

interface ErrorProps {
  apolloError?: ApolloError;
  message?: string;
}

const Error: React.FC<ErrorProps> = (props) => {
  const { apolloError, message } = props;
  return (
    <div className="m-auto text-lg text-center">
      <h1 className="text-4xl font-bold mb-5">Error</h1>
      {apolloError?.message ? (
        <p className="text-xl mb-5">{apolloError.message}</p>
      ) : null}
      {message ? <p className="text-xl mb-5">{message}</p> : null}
      <p>
        If this is in error or you have any questions, contact us at{' '}
        <a href="tel:+18888784588" className="underline">
          1-888-878-4588
        </a>{' '}
        or{' '}
        <a href="mailto:sales@foxquilt.com" className="underline">
          sales@foxquilt.com
        </a>{' '}
        .
      </p>
    </div>
  );
};

export default Error;
