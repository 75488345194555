import React from 'react';

const EndorsementComplete: React.FC = () => (
  <p className="m-auto text-lg">
    <p>Thank you!</p>
    <p>You will receive the updated policy documents in your email.</p>
    <p>The Foxquilt team</p>
  </p>
);
export default EndorsementComplete;
