import './customerPortal.css';

import { useAuth0 } from '@auth0/auth0-react';
import React, { useState } from 'react';
import { Redirect } from 'react-router';

import Loading from '../../components/Loading';
import { useGetAuth0UserPoliciesQuery } from '../../generated/graphql';
import { storeLogout, storeSessionSignout } from '../../utils/trackLogin';

const CustomerPortal: React.FC = () => {
  const { logout } = useAuth0();
  const {
    data,
    error: getPoliciesError,
    loading: getPoliciesLoading
  } = useGetAuth0UserPoliciesQuery({});

  const [policyToView, setPolicyToView] = useState<string>('');

  if (getPoliciesLoading) {
    return <Loading />;
  }

  if (getPoliciesError || !data) {
    throw Error('found no polices');
  }

  if (policyToView !== '') {
    return <Redirect push to={'/customer-portal/' + policyToView} />;
  }
  const policies = data.getAuth0UserPolicies.map((policy) => {
    return (
      <div key={policy} className="row-span-1">
        <button
          onClick={() => setPolicyToView(policy)}
          className="underline text-secondary pl-6"
        >
          {policy}
        </button>
      </div>
    );
  });

  return (
    <div className="container">
      <div className="m-auto text-center ">
        <div className="pt-10 text-xl mb-4 font-bold uppercase">policies</div>

        <div className="grid place-items-start justify-center">{policies}</div>
        <div className="py-3"></div>
        <button
          className="bg-tertiary py-2 px-6 text-base font-bold rounded-md uppercase"
          onClick={() => {
            storeLogout();
            storeSessionSignout();
            logout({ returnTo: window.location.origin });
          }}
        >
          Logout
        </button>
        <div className="py-3"></div>
      </div>
    </div>
  );
};

export default CustomerPortal;
