import './customerPortal.css';

import React from 'react';

import { PolicyDetail, UnderwritingAddress } from '../../generated/graphql';

interface ShowPolicyDetailsParams {
  policyId: string;
  policyDetail: PolicyDetail;
}

const presentAddress = (address: UnderwritingAddress) => {
  return (
    <div>
      {address.street},
      <br />
      {address.city} {address.province}, {address.postalCode}
    </div>
  );
};

const addressesAreIdentical = (
  addressA: UnderwritingAddress,
  addressB: UnderwritingAddress
) => {
  return (
    addressA.street === addressB.street &&
    addressA.postalCode === addressB.postalCode &&
    addressA.province === addressB.province &&
    addressA.city === addressB.city
  );
};

const PolicyDetails: React.FC<ShowPolicyDetailsParams> = ({
  policyId,
  policyDetail
}) => {
  return (
    <div className="grid grid-cols-2 gap-y-4 place-items-start place-content-center">
      <div className="pt-10 row-start-1 col-span-2 text-xl text-blue uppercase font-bold">
        Policy Summary
      </div>
      <div className="row-start-2 font-bold">Policy Number:</div>
      <div className="row-start-2">{policyId}</div>
      <div className="row-start-3 font-bold">Named Insured:</div>
      <div className="row-start-3">{policyDetail.namedInsured}</div>
      <div className="row-start-4 font-bold">Policy Period:</div>
      <div className="row-start-4">
        From: {policyDetail.policyStartDate}
        <br />
        End: {policyDetail.policyEndDate}
      </div>
      <div className="row-start-5 font-bold">Operations:</div>
      <div className="row-start-5">
        {policyDetail.operations.map((profession) => {
          return <div key={profession}>{profession}</div>;
        })}
      </div>
      <div className="row-start-6 font-bold">Mailing Address:</div>
      <div className="row-start-6">
        {presentAddress(policyDetail.mailingAddress)}
      </div>
      {addressesAreIdentical(
        policyDetail.insuredLocation,
        policyDetail.mailingAddress
      ) ? null : (
        <div className="row-start-7 font-bold">Insured Location:</div>
      )}
      {addressesAreIdentical(
        policyDetail.insuredLocation,
        policyDetail.mailingAddress
      ) ? null : (
        <div className="row-start-7">
          {presentAddress(policyDetail.insuredLocation)}
        </div>
      )}
    </div>
  );
};

export default PolicyDetails;
