import * as Sentry from '@sentry/react';

import getEnv from './utils/getEnv';

const { REACT_APP_SENTRY_DSN, REACT_APP_STAGE } = getEnv();

Sentry.init({
  dsn: REACT_APP_SENTRY_DSN,
  environment: REACT_APP_STAGE
});
