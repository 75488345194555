import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ProtectedRoute from './auth/protected-route';
import CancellationComplete from './pages/CancellationComplete/CancellationComplete';
import Complete from './pages/Complete/Complete';
import CustomerPortal from './pages/CustomerPortal';
import PolicyDetails from './pages/CustomerPortal/downloadDocument';
import EndorsementComplete from './pages/EndorsementComplete/EndorsementComplete';
import InitiatePayment from './pages/InitiatePayment/InitiatePayment';
import Invoice from './pages/Invoice';
import Login from './pages/Login/login';
import NotFound from './pages/NotFound/NotFound';
import PaymentFailure from './pages/PaymentFailure/PaymentFailure';
import TCexpired from './pages/TCexpired/TCexpired';
import Terms from './pages/Terms/Terms';
import Unauthorized from './pages/Unauthorized/Unauthorized';
import UpdateCardCancel from './pages/UpdateCardCancel/UpdateCardCancel';
import UpdateCardSuccess from './pages/UpdateCardSuccess/UpdateCardSuccess';

const Routes: React.FC = () => {
  return (
    <Switch>
      <ProtectedRoute exact path="/complete/:invoiceId" component={Complete} />
      <ProtectedRoute exact path="/invoice" component={Invoice} />
      <ProtectedRoute
        exact
        path="/payment/:invoiceId"
        component={InitiatePayment}
      />
      <ProtectedRoute exact path="/terms/:quoteId" component={Terms} />
      <ProtectedRoute exact path="/terms/" component={Terms} />

      <ProtectedRoute
        exact
        path="/customer-portal"
        component={CustomerPortal}
      />
      <ProtectedRoute
        exact
        path="/customer-portal/:policyId"
        component={PolicyDetails}
      />
      <Route exact path="/" component={Login} />
      <Route exact path="/payment-failure" component={PaymentFailure} />
      <Route exact path="/update-card-cancel" component={UpdateCardCancel} />
      <Route exact path="/update-card-success" component={UpdateCardSuccess} />
      <Route exact path="/unauthorized" component={Unauthorized} />
      <Route exact path="/tc-expired" component={TCexpired} />
      <Route
        exact
        path="/endorsement-complete"
        component={EndorsementComplete}
      />
      <Route
        exact
        path="/cancellation-complete"
        component={CancellationComplete}
      />

      {/* NotFound needs to be the last one */}
      <Route component={NotFound} />
    </Switch>
  );
};

export default Routes;
