import './InvoiceDetails.css';

import React from 'react';

import {
  PaymentMethod,
  UserPaymentPlanOption
} from '../../../generated/graphql';
import { formatCAD } from '../../../utils/currencyFormat';

interface InvoiceDetailsProps {
  paymentMethod: PaymentMethod;
  paymentPlanOption: UserPaymentPlanOption;
  isInitial: boolean;
  country: string;
}

const InvoiceDetails: React.FC<InvoiceDetailsProps> = ({
  paymentMethod,
  paymentPlanOption,
  isInitial,
  country
}) => {
  const {
    invoiceNumber,
    invoiceDate,
    quoteNumber,
    totalPremiumAmount: totalPremium,
    totalSurcharge: surcharge,
    totalTaxes: tax,
    totalServiceFees: serviceFee,
    totalPolicyFees: policyFee,
    totalPayable: payable,
    downPayment,
    installmentAmount,
    paymentSchedule,
    installmentFrequency,
    numberOfInstallments
  } = paymentPlanOption;

  const isCreditCard = paymentMethod === PaymentMethod.CreditCard;

  return (
    <table className="InvoiceDetails m-2" data-testid="InvoiceDetails">
      <thead>
        <tr>
          <th className="uppercase text-left" colSpan={2}>
            Invoice Details
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Invoice Number:</td>
          <td>{invoiceNumber}</td>
        </tr>
        <tr>
          <td>Invoice Date:</td>
          <td>{invoiceDate}</td>
        </tr>
        <tr>
          <td>Quote Number:</td>
          <td>{quoteNumber}</td>
        </tr>
        <tr>
          <td>
            Premium Amount: <b>*</b>
          </td>
          <td>{formatCAD.format(totalPremium)}</td>
        </tr>
        <tr>
          <td>
            Taxes/Surcharges: <b>*</b>
          </td>
          <td>{formatCAD.format(tax + surcharge)}</td>
        </tr>
        <tr>
          <td>
            Policy Fees: <b>**</b>
          </td>
          <td>{formatCAD.format(policyFee)}</td>
        </tr>
        {isCreditCard ? (
          <tr>
            <td>
              Service Fees: <b>**</b>
            </td>
            <td>{formatCAD.format(serviceFee)}</td>
          </tr>
        ) : null}
        <tr>
          <td>Total Payable:</td>
          <td>{formatCAD.format(payable)}</td>
        </tr>
        {isInitial && country === 'Canada' ? (
          <tr>
            <td colSpan={2} className="text-sm NORMAL">
              <b>* 25% Minimum earned premium AND non&#8209;refundable</b>
            </td>
          </tr>
        ) : (
          <tr>
            <td colSpan={2} className="text-sm NORMAL">
              <b>
                * Policy may be subject to a cancellation penalty. See policy
                for details.
              </b>
            </td>
          </tr>
        )}
        <tr>
          <td colSpan={2} className="text-sm NORMAL">
            <b>** Fully earned and non-refundable</b>
          </td>
        </tr>
        {paymentPlanOption.paymentPlan !== 'Full Pay'
          ? [
              <tr key={'Due Now'}>
                <td>Due Now:</td>
                <td>{formatCAD.format(downPayment)}</td>
              </tr>,
              <tr key={'installmentAmount'}>
                <td>Installment Amount:</td>
                <td>{formatCAD.format(installmentAmount)}</td>
              </tr>,
              <tr key={'installmentFrequency'}>
                <td>Installment Frequency:</td>
                <td>{installmentFrequency}</td>
              </tr>,
              <tr key={'firstInstallment'}>
                <td>First Installment:</td>
                <td>{paymentSchedule[0]}</td>
              </tr>,
              <tr key={'numberOfInstallment'}>
                <td>Number of Installment:</td>
                <td>{numberOfInstallments}</td>
              </tr>
            ]
          : null}
      </tbody>
    </table>
  );
};

export default InvoiceDetails;
