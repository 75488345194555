import React, { useRef } from 'react';
import CurrencyInput from 'react-currency-input-field';

import { PaymentMethod } from '../../generated/graphql';
import useRunIfInvoiceNumberExists from '../../hooks/useRunIfInvoiceNumberExists';
import { calTotalPayable } from '../../utils/calTotalPayable';
import { formatCAD } from '../../utils/currencyFormat';
import { InvoiceInfoState } from './InvoiceData';
import { InvoiceEvent } from './type';

interface ClientDetailsProps {
  invoiceData: InvoiceInfoState;
  handleInvoiceInputChange: (event: InvoiceEvent) => void;
}

const InvoiceDetails: React.FC<ClientDetailsProps> = ({
  handleInvoiceInputChange,
  invoiceData: {
    invoiceDate,
    invoiceNumber,
    policyFee,
    quoteNumber,
    tax,
    totalPremium
  }
}) => {
  const invoiceNumberInputRef = useRef<HTMLInputElement>(null);

  useRunIfInvoiceNumberExists(invoiceNumber, (isExisted) => {
    const errorMsg = isExisted ? 'Invoice number is already registered' : '';
    const element = invoiceNumberInputRef.current;

    if (element) {
      element.setCustomValidity(errorMsg);
    }
  });

  const { creditCardFee, totalPayable } = calTotalPayable(
    {
      policyFee: +policyFee,
      totalPremium: +totalPremium,
      tax: +tax,
      currency: 'CAD' // TODO: Decide if we need a field to let broker enter currency
    },
    PaymentMethod.CreditCard
  );
  return (
    <table className="my-4 custom-table">
      <thead>
        <tr>
          <th colSpan={2}>INVOICE MANAGEMENT</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>INVOICE NUMBER</td>
          <td>
            <input
              type="text"
              name="invoiceNumber"
              required
              value={invoiceNumber}
              onChange={handleInvoiceInputChange}
              ref={invoiceNumberInputRef}
            />
          </td>
        </tr>
        <tr>
          <td>INVOICE DATE</td>
          <td>
            <input
              type="date"
              name="invoiceDate"
              required
              value={invoiceDate}
              onChange={handleInvoiceInputChange}
            />
          </td>
        </tr>
        <tr>
          <td>QUOTE NUMBER</td>
          <td>
            <input
              type="text"
              name="quoteNumber"
              required
              value={quoteNumber}
              onChange={handleInvoiceInputChange}
            />
          </td>
        </tr>
        <tr>
          <td>PREMIUM AMOUNT</td>
          <td>
            <CurrencyInput
              name="totalPremium"
              allowNegativeValue={false}
              prefix="$"
              required
              value={totalPremium}
              onChange={({ target: { name, value } }) => {
                if (!name) {
                  throw new Error('unreachable: name should be defined');
                }
                handleInvoiceInputChange({ target: { name, value } });
              }}
            />
          </td>
        </tr>
        <tr>
          <td>TAXES</td>
          <td>
            <CurrencyInput
              name="tax"
              allowNegativeValue={false}
              prefix="$"
              required
              value={tax}
              onChange={({ target: { name, value } }) => {
                if (!name) {
                  throw new Error('unreachable: name should be defined');
                }
                handleInvoiceInputChange({ target: { name, value } });
              }}
            />
          </td>
        </tr>
        <tr>
          <td className="uppercase">Policy Fees</td>
          <td>
            <CurrencyInput
              name="policyFee"
              allowNegativeValue={false}
              prefix="$"
              required
              value={policyFee}
              onChange={({ target: { name, value } }) => {
                if (!name) {
                  throw new Error('unreachable: name should be defined');
                }
                handleInvoiceInputChange({ target: { name, value } });
              }}
            />
          </td>
        </tr>
        <tr>
          <td>CREDIT CARD FEES</td>
          <td>{formatCAD.format(creditCardFee)}</td>
        </tr>
        <tr>
          <td>TOTAL PAYABLE</td>
          <td>{formatCAD.format(totalPayable)}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default InvoiceDetails;
