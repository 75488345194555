import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useRef } from 'react';
import { urlJoin } from 'url-join-ts';

import { useAuthContext } from '../../auth/authContext';
import getEnv from '../../utils/getEnv';
import {
  checkIsLoggedIn,
  storeLogin,
  storeLogout,
  storeSessionSignout
} from '../../utils/trackLogin';

const { REACT_APP_PUBLIC_URL } = getEnv();

/**
 * Signout component.
 *
 * Signout component for handling user logout.
 * This component uses the Auth0 library for authentication.
 * It checks if the user is already authenticated and performs the logout process accordingly.
 * If the user is on the terms page or customer portal page and is logged in with a different email address than the login hint,
 * it stores the logout information and redirects the user to the specified returnTo URL after a delay.
 * If the user is already authenticated, it stores the login information.
 * If the user is not authenticated, it stores the logout information.
 *
 *
 * @returns null
 */
const Signout = (): null => {
  const { isAuthenticated, logout, user } = useAuth0();
  const { login_hint } = useAuthContext();
  const didRunRef = useRef(false);

  useEffect(() => {
    const origin = urlJoin(window.location.origin, REACT_APP_PUBLIC_URL) + '/';

    const wasLoggedIn = checkIsLoggedIn();

    const pathName = window.location.pathname?.toLocaleLowerCase();
    const isTermsPage = pathName?.includes('terms');
    const isCustomerPortalPage = pathName?.includes('customer-portal');
    const isToBeLogoutPage = isTermsPage || isCustomerPortalPage;
    const areEmailaddressesEqual = isCustomerPortalPage
      ? false
      : user?.email?.toLowerCase() === login_hint?.toLowerCase();

    if (wasLoggedIn && isToBeLogoutPage && !areEmailaddressesEqual) {
      if (didRunRef.current) return;
      didRunRef.current = true;
      storeLogout();
      storeSessionSignout();
      setTimeout(
        () =>
          //TODO: Add assertion to check if the user is logged out (the code below was already covered by the dev test)
          logout({
            returnTo: isTermsPage ? window.location.href : origin
          }),
        21
      );
    } else {
      // Check if the user is already authenticated
      if (isAuthenticated) {
        storeLogin();
      } else {
        storeLogout();
      }
    }
  }, [isAuthenticated, logout, login_hint, user?.email]);

  return null; // This component doesn't render anything
};

export default Signout;
