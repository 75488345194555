import Checkbox from '@material-ui/core/Checkbox';
import React from 'react';

import { UserPaymentPlanOption } from '../../../generated/graphql';
import { PlanOptionRadio } from './Radio/Radio';

interface PaymentPlanOptionsProps {
  userPlanData: Array<UserPaymentPlanOption>;
  paymentPlanOption: UserPaymentPlanOption | undefined;
  setPaymentPlanOption: (method: UserPaymentPlanOption) => void;
  useCardForRenewals: boolean;
  setUseCardForRenewals: (method: boolean) => void;
}

const PaymentPlanOptions: React.FC<PaymentPlanOptionsProps> = ({
  userPlanData,
  paymentPlanOption,
  setPaymentPlanOption,
  useCardForRenewals,
  setUseCardForRenewals
}) => {
  const optionNames = userPlanData.map((item) => {
    return item.paymentPlan;
  });

  const handlePaymentPlanOptionChange = (
    e: React.FormEvent<HTMLDivElement>
  ) => {
    const method: string = (e.target as HTMLInputElement).value;
    const plan = userPlanData.find((item) => {
      return item.paymentPlan === method;
    });
    if (!plan) {
      throw Error(`cannot find corresponding planId for ${method}`);
    }
    setPaymentPlanOption(plan);
  };

  const fullPayPlan = userPlanData.find((item) => {
    return item.paymentPlan === 'Full Pay';
  });

  if (fullPayPlan === undefined) {
    throw Error('cannot find Full Pay plan');
  }

  if (paymentPlanOption === undefined) {
    setPaymentPlanOption(fullPayPlan);
  }

  const planRadios = ((items: string[]) => {
    const lst = [];
    const lstFirst = [];
    for (let i = 0; i < items.length; i++) {
      if (items[i] === 'Full Pay')
        lstFirst.push(
          <tr key={i}>
            <td>
              <PlanOptionRadio value={items[i]} defaultChecked />
            </td>
            <td>{items[i]}</td>
          </tr>
        );
      else {
        lst.push(
          <tr key={i}>
            <td>
              <PlanOptionRadio value={items[i]} />
            </td>
            <td>{items[i]}</td>
          </tr>
        );
      }
    }
    return lstFirst.concat(lst);
  })(optionNames);
  return (
    <div className="PaymentPlanOptions m-2">
      <table className="payment-options">
        <thead>
          <tr>
            <th className="uppercase text-left" colSpan={2}>
              Payment Plan Options
            </th>
          </tr>
        </thead>
        <tbody onChange={handlePaymentPlanOptionChange}>
          {planRadios}
          <div className="py-12"></div>
        </tbody>
        <tr>
          <td>
            <Checkbox
              style={{ paddingLeft: '0px' }}
              size="small"
              checked={useCardForRenewals}
              color="primary"
              onChange={() => {
                setUseCardForRenewals(!useCardForRenewals);
              }}
            />
          </td>
          <td className="font-bold max-w-xs">
            I consent that this card will be used for all future renewal
            transactions.
          </td>
        </tr>
      </table>
    </div>
  );
};

export default PaymentPlanOptions;
