// eslint-disable-next-line import/no-unresolved
import { GraphQLClient } from 'graphql-request';

import { getSdk, Sdk } from '../generated/payment-public-graphql';
import getEnv from '../utils/getEnv';

export class PaymentPublicClient {
  sdk: Sdk;

  constructor(versionNumber?: string) {
    const graphQLClient = new GraphQLClient(
      PaymentPublicClient.getPaymentURL(versionNumber)
    );
    this.sdk = getSdk(graphQLClient);
  }

  /**
   * Returns a SDK of rating-quoting backend.
   * @returns a sdk that has all APIs of rating-quoting backend.
   */
  public getSDK(): Sdk {
    return this.sdk;
  }

  /**
   * Retrives email address by quote id.
   *
   * @param {string} quoteId ID of quote to get email address.
   * @returns {string} Returns a Promise for a string of email address
   */
  public async getEmailAddressByQuoteId(quoteId: string): Promise<string> {
    const result = await this.sdk.getEmailAddressByQuoteId({
      quoteId
    });
    return result.getEmailAddressByQuoteId;
  }

  static versionUrl(baseUrl: string, versionNumber?: string): string {
    // regex to replace version number in url
    const regex = /\d{4}-\d{2}-\d{2}/g;
    return versionNumber ? baseUrl.replace(regex, versionNumber) : baseUrl;
  }

  public static getPaymentURL(versionNumber?: string): string {
    const { REACT_APP_PAYMENT_PUBLIC_GRAPHQL_URL } = getEnv();

    return PaymentPublicClient.versionUrl(
      REACT_APP_PAYMENT_PUBLIC_GRAPHQL_URL,
      versionNumber
    );
  }
}
