import './Loading.css';

import React from 'react';

import Animation from '../../images/Animation.webp';
import Logo from '../../images/logo-brand.png';

interface MessageLoadingProps {
  headingMessage: string;
  subHeadingMessage: string;
}

const Loading: React.FC = () => (
  <div className="Loading LoadingContainer" data-testid="Loading">
    <img className="logo" src={Logo} alt="logo" />
  </div>
);

export const MessageLoading: React.FC<MessageLoadingProps> = (props) => {
  const { headingMessage, subHeadingMessage } = props;
  return (
    <div
      className="MessageLoadingContainer bg-white rounded-xl m-auto w-1/3 h-2/3"
      data-testid="MessageLoading"
    >
      <img className="animative m-auto h-2/3" src={Animation} alt="logo" />
      <p className="headingMessage text-black text-center text-2xl">
        <b>{headingMessage}</b>
      </p>
      <p className="subHeadingMessage text-black text-center text-base mb-8">
        {subHeadingMessage}
      </p>
    </div>
  );
};

export default Loading;
