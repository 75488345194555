import './login.css';

import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { Redirect } from 'react-router-dom';

import Loading from '../../components/Loading';
import { storeLogout, storeSessionSignout } from '../../utils/trackLogin';

function Login(): JSX.Element {
  const {
    isLoading,
    isAuthenticated,
    error,
    loginWithRedirect,
    logout
  } = useAuth0();

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return (
      <div id="myModal" className="modal">
        <div className="modal-content">
          <p className="mb-4 text-xl">Login Error</p>
          <p className="mb-4">
            Incorrect email address or password; please try again
          </p>
          <button
            onClick={() => {
              storeLogout();
              storeSessionSignout();
              logout({ returnTo: window.location.origin });
            }}
          >
            CLOSE
          </button>
        </div>
      </div>
    );
  }

  if (!isAuthenticated) {
    return (
      <div className="m-auto text-center">
        <>
          <p className="text-2xl mb-20 font-bold uppercase">Please sign in</p>
          <button
            className="bg-orange py-2 px-10 text-white font-bold rounded-md uppercase"
            onClick={loginWithRedirect}
          >
            Login
          </button>
        </>
      </div>
    );
  }
  return <Redirect to={'/customer-portal'} />;
}

export default Login;
