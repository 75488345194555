import React, { useContext } from 'react';

import ContactBarContext from '../../context/ContactBarContext';
import ContactBar from './ContactBar/ContactBar';

const Footer: React.FC = () => {
  const { isShowContactBar } = useContext(ContactBarContext);
  return (
    <footer
      className={`bg-white text-base flex flex-col items-center ${
        isShowContactBar ? 'pt-4' : 'pt-12'
      }`}
      data-testid="Footer"
    >
      {isShowContactBar ? <ContactBar /> : null}
      <div className="bottom-0 right-0 text-primary pb-2 pr-2 self-end">
        Copyright © {new Date().getFullYear()} Foxquilt. All Rights Reserved.
      </div>
    </footer>
  );
};

export default Footer;
