import React, { useCallback, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { Redirect, useParams } from 'react-router-dom';

import Loading from '../../components/Loading';
import {
  PaymentMethod,
  useGetPaymentRouteDeciderQuery,
  UserPaymentPlanOption
} from '../../generated/graphql';
import { PaymentCompleteMessage, QuickComplete } from '../Complete/Complete';
import InvoiceDetails from './InvoiceDetails/InvoiceDetails';
import PaymentOptions from './PaymentOptions/PaymentOptions';
import PaymentPlanOptions from './PaymentPlanOptions/PaymentPlanOptions';
import PaymentRedirectModal from './PaymentRedirectModal/PaymentRedirectModal';
import ProceedButton from './ProceedButton/ProceedButton';

interface InitiatePaymentParams {
  invoiceId: string;
}

const CANADA_PROVINCES = [
  'Alberta',
  'British Columbia',
  'Manitoba',
  'New Brunswick',
  'Newfoundland and Labrador',
  'Northwest Territories',
  'Nova Scotia',
  'Nunavut',
  'Ontario',
  'Prince Edward Island',
  'Quebec',
  'Saskatchewan',
  'Yukon'
];

const pushDataLayer = (country: string, state: string, step: string): void => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'form-progress',
      type: 'commercial',
      country,
      'state/province': state,
      step
    }
  };
  TagManager.dataLayer(tagManagerArgs);
};

const InitiatePayment: React.FC = () => {
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(
    PaymentMethod.CreditCard
  );
  const [
    paymentPlanOption,
    setPaymentPlanOption
  ] = useState<UserPaymentPlanOption>();
  const [purchaseStartTime, setPurchaseStartTime] = useState<number | null>(
    null
  );
  const [isOpenModal, setOpenModel] = useState(false);
  const [useCardForRenewals, setUseCardForRenewals] = useState(true);
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const { invoiceId } = useParams<InitiatePaymentParams>();
  const { data, loading, error } = useGetPaymentRouteDeciderQuery({
    variables: { invoiceId: invoiceId }
  });

  const onClickProcessBtn = useCallback(() => {
    setOpenModel(true);
    pushDataLayer(country, state, 'Munich_Payment2');
  }, [country, state]);

  useEffect(() => {
    setPurchaseStartTime(new Date().getTime());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      // data?.getInvoice.__typename === 'GetInvoiceSuccess' &&
      // data.getInvoice.invoiceInfo
      data?.paymentRouteDecider.__typename === 'InitialSuccess' &&
      data.paymentRouteDecider.getInvoiceDeciderResult.__typename ===
        'GetInvoiceSuccess'
    ) {
      const {
        invoiceInfo: { client }
      } = data.paymentRouteDecider.getInvoiceDeciderResult;

      if (client) {
        const { region: stateName } = client.address;
        const countryName = CANADA_PROVINCES.includes(stateName)
          ? 'Canada'
          : 'United States of America';
        setCountry(countryName);
        setState(stateName);
        pushDataLayer(countryName, stateName, 'Munich_Payment1');
      }
    }
  }, [data]);

  if (loading) {
    return <Loading />;
  }

  if (error || !data) {
    if (purchaseStartTime && timeout(purchaseStartTime)) {
      //TODO: delete it
      return <QuickComplete />;
    } else {
      return <Redirect to="/404" />;
    }
  }

  const { paymentRouteDecider: paymentRouteDeciderData } = data;

  switch (paymentRouteDeciderData.__typename) {
    case 'FoxdenError': {
      return <PaymentCompleteMessage isNotFound />;
    }
    case 'IsExpired': {
      return <PaymentCompleteMessage isExpired />;
    }
    case 'IsPaid': {
      const { policyNumber } = paymentRouteDeciderData;
      return <PaymentCompleteMessage policyFoxdenId={policyNumber} />;
    }
    case 'WaiveSuccess': {
      return (
        <Redirect
          to={{
            pathname: `/complete/${invoiceId}`,
            state: { flowKind: 'WaiveSuccess' }
          }}
        />
      );
    }
    case 'RefundFullPaySuccess': {
      return (
        <Redirect
          to={{
            pathname: `/complete/${invoiceId}`,
            state: { flowKind: 'RefundFullPaySuccess' }
          }}
        />
      );
    }
    case 'RefundInstallmentSuccess': {
      return (
        <Redirect
          to={{
            pathname: `/complete/${invoiceId}`,
            state: { flowKind: 'RefundInstallmentSuccess' }
          }}
        />
      );
    }
    case 'CancellationSuccess': {
      return (
        <Redirect
          to={{
            pathname: `/complete/${invoiceId}`,
            state: { flowKind: 'CancellationSuccess' }
          }}
        />
      );
    }
    case 'InitialSuccess': {
      if (isOpenModal) {
        if (!paymentPlanOption) {
          throw Error('paymentPlanOption is not selected');
        }
        return (
          <PaymentRedirectModal
            invoiceId={invoiceId}
            paymentMethod={paymentMethod}
            paymentPlanId={paymentPlanOption.paymentPlanId}
            useCardForRenewals={useCardForRenewals}
            setClose={() => setOpenModel(false)}
          />
        );
      }
      const {
        userPaymentPlanOptionDeciderResult: {
          userPaymentPlanOption: userPlanData
        }
      } = paymentRouteDeciderData;
      return (
        <div
          className="m-auto text:lg sm:text-xl"
          data-testid="InitiatePayment"
        >
          <div className="container">
            <div className="uppercase text-left p-2 text-2xl bg-secondary rounded-md border-none font-bold">
              Complete Payment
            </div>
            <div className="text-xl my-4">
              Please choose a payment option and complete your payment
            </div>
            <div className="grid">
              <div className="col-span-1 uppercase text-left p-2 text-lg bg-gray-400 rounded-md border-none font-bold mb-4">
                Billing Information
              </div>
              {paymentPlanOption ? (
                <div className="pl-10 sm:row-start-2 sm:col-span-auto">
                  <InvoiceDetails
                    paymentMethod={paymentMethod}
                    paymentPlanOption={paymentPlanOption}
                    isInitial={true}
                    country={country}
                  />
                </div>
              ) : null}
              <div className="pl-10 sm:row-start-2 sm:col-span-auto">
                <PaymentOptions
                  paymentMethod={paymentMethod}
                  setPaymentMethod={setPaymentMethod}
                />
              </div>
              <div className="pl-10 sm:row-start-2 sm:col-span-auto">
                <PaymentPlanOptions
                  userPlanData={userPlanData}
                  paymentPlanOption={paymentPlanOption}
                  setPaymentPlanOption={setPaymentPlanOption}
                  useCardForRenewals={useCardForRenewals}
                  setUseCardForRenewals={setUseCardForRenewals}
                />
              </div>
            </div>
            {paymentMethod !== undefined ? (
              <div className="text-center my-4">
                <ProceedButton
                  paymentMethod={paymentMethod}
                  onClick={() => setOpenModel(true)}
                />
              </div>
            ) : null}
          </div>
        </div>
      );
    }
    case 'ChargeSuccess': {
      if (isOpenModal) {
        if (!paymentPlanOption) {
          throw Error('paymentPlanOption is not selected');
        }
        return (
          <PaymentRedirectModal
            invoiceId={invoiceId}
            paymentMethod={paymentMethod}
            paymentPlanId={paymentPlanOption.paymentPlanId}
            useCardForRenewals={useCardForRenewals}
            setClose={() => setOpenModel(false)}
          />
        );
      }
      const {
        userPaymentPlanOptionDeciderResult: {
          userPaymentPlanOption: userPlanData
        }
      } = paymentRouteDeciderData;
      return (
        <div
          className="InitiatePayment m-auto text:lg sm:text-xl"
          data-testid="InitiatePayment"
        >
          <div className="container">
            <div className="uppercase text-left p-2 text-2xl bg-secondary rounded-md border-none font-bold">
              Complete Payment
            </div>
            <div className="text-xl my-4">
              Please choose a payment option and complete your payment
            </div>
            <div className="grid">
              <div className="col-span-1 uppercase text-left p-2 text-lg bg-gray-400 rounded-md border-none font-bold mb-4">
                Billing Information
              </div>
              {paymentPlanOption ? (
                <div className="pl-10 sm:row-start-2 sm:col-span-auto">
                  <InvoiceDetails
                    paymentMethod={paymentMethod}
                    paymentPlanOption={paymentPlanOption}
                    isInitial={false}
                    country={country}
                  />
                </div>
              ) : null}
              <div className="pl-10 sm:row-start-2 sm:col-span-auto">
                <PaymentOptions
                  paymentMethod={paymentMethod}
                  setPaymentMethod={setPaymentMethod}
                />
              </div>
              <div className="pl-10 sm:row-start-2 sm:col-span-auto">
                <PaymentPlanOptions
                  userPlanData={userPlanData}
                  paymentPlanOption={paymentPlanOption}
                  setPaymentPlanOption={setPaymentPlanOption}
                  useCardForRenewals={useCardForRenewals}
                  setUseCardForRenewals={setUseCardForRenewals}
                />
              </div>
            </div>
            {paymentMethod !== undefined ? (
              <div className="text-center my-4">
                <ProceedButton
                  paymentMethod={paymentMethod}
                  onClick={onClickProcessBtn}
                />
              </div>
            ) : null}
          </div>
        </div>
      );
    }
    default:
      return <PaymentCompleteMessage isNotFound />;
  }
};

const timeout = (startTime: number): boolean => {
  // Timeout of lambda function is 29 seconds.
  const timeLimit = 28.9 * 1000;
  const currentTime = new Date().getTime();
  //TODO delete it
  if (startTime + timeLimit < currentTime) {
    return true;
  }

  return false;
};

export default InitiatePayment;
