import React from 'react';
import { useParams } from 'react-router-dom';

import Loading from '../../components/Loading';
import {
  useGetDocumentInformationQuery,
  useSendCoiMutation
} from '../../generated/graphql';
import Coi from './coi';
import DownloadPortal from './downloadPortal';
import ShowPolicyDetails from './policyDetails';

interface PolicyDetailsParams {
  policyId: string;
}

const PolicyDetails: React.FC = () => {
  const { policyId } = useParams<PolicyDetailsParams>();
  const {
    data,
    error: getPolicyDetailsError,
    loading: getPolicyDetailsLoading
  } = useGetDocumentInformationQuery({ variables: { policyId: policyId } });
  const [
    sendcoi,
    { data: sendCoiData, loading: sendCoiLoading, error: sendCoiError }
  ] = useSendCoiMutation();
  if (getPolicyDetailsLoading || sendCoiLoading) {
    return <Loading />;
  }

  if (getPolicyDetailsError || !data || !data.getDocumentInformation) {
    throw Error('found no polices');
  }

  const documentInformation = data.getDocumentInformation;

  return (
    <div className="container">
      <div className="grid place-items-center h-full ">
        <div className="col-start-1 h-full">
          <ShowPolicyDetails
            policyDetail={documentInformation.policyDetails}
            policyId={policyId}
          />
        </div>
        <div className="md:col-start-2 h-full">
          <DownloadPortal
            documentDownloadLink={documentInformation.documentDownloadLink}
          />
        </div>
        <div
          className="md:col-start-3 h-full invisible"
          style={{ display: 'none' }}
        >
          <Coi
            country={documentInformation.policyDetails.country}
            coiData={sendCoiData}
            sendCoi={sendcoi}
            policyId={policyId}
            coiError={sendCoiError}
          ></Coi>
        </div>
      </div>
    </div>
  );
};

export default PolicyDetails;
