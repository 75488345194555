import './App.css';

import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

import Auth0ProviderWithHistory from './auth/auth0-provider-with-history';
import Footer from './components/Footer';
import Header from './components/Header';
import ContactBarContext from './context/ContactBarContext';
import Routes from './Routes';
import getEnv from './utils/getEnv';

const { REACT_APP_HUBSPOT_ACCOUNT } = getEnv();

const App: React.FC = () => {
  useEffect(() => {
    // add hubspot tracking script
    const script = document.createElement('script');
    const hubspotAccount = REACT_APP_HUBSPOT_ACCOUNT;
    script.src = `//js.hs-scripts.com/${hubspotAccount}.js`;
    script.async = true;
    script.defer = true;
    script.type = 'text/javascript';
    document.body.appendChild(script);
  }, []);

  const [isShowContactBar, setShowContactBar] = useState(false);
  return (
    <BrowserRouter basename={process.env.REACT_APP_PUBLIC_URL}>
      <Auth0ProviderWithHistory>
        <ContactBarContext.Provider
          value={{ isShowContactBar, setShowContactBar }}
        >
          <div
            data-testid="App"
            className="grid grid-rows-main h-full w-full text-quartary"
          >
            <Header usedWithRoutes={true} />
            {/* put flex class here because the div overrides the grid layout,
        and we need it for m-auto to center div element in the children */}
            <div className="flex">
              <Routes />
            </div>
            <Footer />
          </div>
        </ContactBarContext.Provider>
      </Auth0ProviderWithHistory>
    </BrowserRouter>
  );
};

export default App;
