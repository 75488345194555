import { createContext, useContext } from 'react';

/**
 * Represents the value of the authentication context.
 */
export type AuthContextValueType = {
  login_hint: string | undefined;
};

/**
 * Context object for authentication.
 */
export const AuthContext = createContext<AuthContextValueType>({
  login_hint: undefined
});

/**
 * Custom hook that provides access to the authentication context.
 * @returns The authentication context value.
 */
export const useAuthContext = (): AuthContextValueType =>
  useContext(AuthContext);
