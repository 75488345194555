import './styles/main.css';
import './index.css';
// sets up sentry
import './sentry';

import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';

import App from './App';
import { AuthProvider } from './auth/AuthProvider';
import ApolloApp from './components/ApolloApp';
import * as serviceWorker from './serviceWorker';
import getEnv from './utils/getEnv';

const { REACT_APP_GTM_ID: gtmId } = getEnv();

// Initialize GTM
const tagManagerArgs = {
  gtmId
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <ApolloApp>
        <App />
      </ApolloApp>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
