import React from 'react';

const CancellationComplete: React.FC = () => (
  <p className="m-auto text-lg">
    <p>Thank you!</p>
    <p>You will receive the cancellation confirmation in your email.</p>
    <p>The Foxquilt team</p>
  </p>
);
export default CancellationComplete;
