import React from 'react';

interface RadioProps {
  defaultChecked?: boolean;
  disabled?: boolean;
  value: string;
}
function radioGenerator(name: string): React.FC<RadioProps> {
  return ({ defaultChecked, disabled, value }) => (
    <input
      className="Radio"
      data-testid="Radio"
      type="radio"
      value={value}
      name={name}
      defaultChecked={defaultChecked}
      disabled={disabled}
    />
  );
}

export const PlanOptionRadio = radioGenerator('radio');
