import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';

import Loading from '../../../components/Loading';
import Modal from '../../../components/Modal/Modal';
import {
  CreatePaymentSessionInput,
  PaymentMethod,
  SessionData,
  useCreatePaymentSessionMutation
} from '../../../generated/graphql';
import getEnv from '../../../utils/getEnv';

/**
 * Stripe API
 */
const {
  REACT_APP_STRIPE_CA_PUBLISHABLE_KEY,
  REACT_APP_STRIPE_US_PUBLISHABLE_KEY
} = getEnv();

const { REACT_APP_MONERIS_ENDPOINT } = getEnv();
const MONERIS_FORM_ID = 'moneris-preprocessing-form';
const MONERIS_HPP_ID_LABEL = 'moneris_hpp_id';
const MONERIS_TICKET_LABEL = 'moneris_ticket';

const SUPPORTED_PAYMENT_METHOD = new Set([PaymentMethod.CreditCard]);

const getInputById = (id: string) => {
  const ret = document.getElementById(id) as HTMLInputElement;
  if (!ret) {
    throw new Error(`bug: input component not found, id: [${id}]`);
  }
  return ret;
};

interface PaymentRedirectModalProps {
  invoiceId: string;
  paymentMethod: PaymentMethod;
  paymentPlanId: number;
  useCardForRenewals: boolean;
  setClose: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const PaymentRedirectModal: React.FC<PaymentRedirectModalProps> = ({
  invoiceId,
  paymentMethod,
  paymentPlanId,
  useCardForRenewals,
  setClose
}) => {
  const [paymentSession, setPaymentSession] = useState<SessionData>();
  const [loading, setLoading] = useState<boolean>(true);
  const [createPaymentSession] = useCreatePaymentSessionMutation();

  useEffect(() => {
    // Payment information to send
    const createPaymentSessionInput: CreatePaymentSessionInput = {
      invoiceId,
      paymentPlanId,
      method: paymentMethod,
      useCardForRenewals
    };
    const fetchPaymentSession = async () => {
      // Make request for session id
      const { data: createPaymentData } = await createPaymentSession({
        variables: { paymentInfo: createPaymentSessionInput }
      });
      if (!createPaymentData) {
        throw new Error('createPaymentData should be ready');
      }
      setPaymentSession(createPaymentData.createPaymentSession);
      setLoading(false);
    };

    fetchPaymentSession();
  }, [
    createPaymentSession,
    invoiceId,
    paymentMethod,
    paymentPlanId,
    useCardForRenewals
  ]);
  if (!SUPPORTED_PAYMENT_METHOD.has(paymentMethod)) {
    throw new Error(`unreachable: ${paymentMethod} not supported`);
  }
  const paymentProvider =
    paymentSession?.__typename === 'MonerisData' ? 'Moneris' : 'Stripe';

  const handlePaymentModalNext = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    // Determine if using Moneris or Stripe session
    switch (paymentSession?.__typename) {
      /**
       * MONERIS AS SERVICE
       */
      case 'MonerisData':
        // Get Moneris tokens from the backend

        // Fill the tokens in a HTML form and use its submission mechanism
        // to starts a Post request to Moneris.
        const form = document.getElementById(
          MONERIS_FORM_ID
        ) as HTMLFormElement;
        if (!form) {
          throw new Error('bug: moneris preprocessing form not found');
        }

        const { hpp_id, ticket } = paymentSession;
        getInputById(MONERIS_HPP_ID_LABEL).value = hpp_id;
        getInputById(MONERIS_TICKET_LABEL).value = ticket;
        form.submit();
        break;
      /**
       * STRIPE AS SERVICE
       */
      case 'StripeData':
        const publishableKey =
          paymentSession.stripePlatformAccount === 'StripePlatformAccount-US'
            ? REACT_APP_STRIPE_US_PUBLISHABLE_KEY
            : REACT_APP_STRIPE_CA_PUBLISHABLE_KEY;
        const stripe = await loadStripe(publishableKey);
        if (stripe) {
          // Stripe API call to redirect to a new session
          await stripe.redirectToCheckout({
            sessionId: paymentSession.session_id
          });
        } else {
          throw new Error(`unreachable: Stripe Redirect failed`);
        }
        break;
      default:
        throw new Error(
          'unexpected: expected either MonerisPreloadData or StripePreloadData'
        );
    }
  };
  return loading ? (
    <Loading />
  ) : (
    <div
      className="PaymentRedirectModal w-full"
      data-testid="PaymentRedirectModal"
    >
      <Modal
        setClose={setClose}
        onAction={handlePaymentModalNext}
        actionName={'NEXT'}
      >
        <form
          id={MONERIS_FORM_ID}
          method="post"
          action={REACT_APP_MONERIS_ENDPOINT}
          className="hidden"
        >
          <input type="hidden" id={MONERIS_HPP_ID_LABEL} name="hpp_id" />
          <input type="hidden" name="hpp_preload" value="" />
          <input type="hidden" id={MONERIS_TICKET_LABEL} name="ticket" />
        </form>
        <div className="px-8 py-12">
          <p>
            {`${paymentProvider} processes the payments for Foxquilt. After clicking
            NEXT you will be redirected to a secure payment page hosted by
            ${paymentProvider}.`}
          </p>
          <p>Your information will not be shared with any third party.</p>
        </div>
      </Modal>
    </div>
  );
};

export default PaymentRedirectModal;
