import getEnv from './getEnv';

const { REACT_APP_API_MAX_DELAY } = getEnv();

export const getDelay = (
  prevDelay: number,
  maxDelay = Number(REACT_APP_API_MAX_DELAY)
): number => {
  // Calculate the next retry delay with exponential backoff and jitter
  const jitter = Math.random() * prevDelay;
  const exponentialBackoff = Math.min(prevDelay * 2, maxDelay); // 2 * prevDelay <= currDelay < 3 * prevDelay
  return Math.min(exponentialBackoff + jitter, maxDelay);
};

export const sleep = async (ms: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, ms));
