import React from 'react';

import { useContactBar } from '../../utils/hooks/useContactBarHook';

const PaymentFailure: React.FC = () => {
  useContactBar();

  return (
    <p className="m-auto text-lg overflow-auto text-center">
      Your card details were not updated. Please click on the link provided in
      the email and try again if needed.
    </p>
  );
};

export default PaymentFailure;
