import { Auth0ContextInterface } from '@auth0/auth0-react';
import React, { useState } from 'react';

import { storeLogout, storeSessionSignout } from '../../utils/trackLogin';

interface IUserSettingProps {
  name: string;
  logout: Auth0ContextInterface['logout'];
}

const UserSetting: React.FC<IUserSettingProps> = ({ name, logout }) => {
  const [isShowLogoutSetting, setShowLogoutSetting] = useState(false);

  return (
    <div
      id="login"
      className="text-right p-2 cursor-pointer font-bold uppercase"
      data-testid="UserSetting"
      onClick={() => setShowLogoutSetting(!isShowLogoutSetting)}
    >
      Welcome {name} &#x25BC;
      {isShowLogoutSetting ? (
        <div>
          <button
            id="logout"
            className="font-bold pl-4 pr-4"
            onClick={() => {
              storeLogout();
              storeSessionSignout();
              logout({ returnTo: window.location.origin });
            }}
          >
            LOG OUT
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default UserSetting;
