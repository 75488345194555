import { captureException } from '@sentry/browser';
import React, { useEffect, useRef, useState } from 'react';
import TagManager from 'react-gtm-module';
import { Redirect, useParams } from 'react-router-dom';

import Loading from '../../components/Loading';
import { useGetPaymentStatusLazyQuery } from '../../generated/graphql';
import { getDelay, sleep } from '../../utils/getDelay';
import { useContactBar } from '../../utils/hooks/useContactBarHook';

/**
 * Constants for exponential backoff.
 * The 1st attempt starts around 6 seconds after the initial request.
 * The 2nd attempt starts around 12 to 18 seconds after the 1st attempt.
 * The 3rd attempt starts around 24 seconds after the 2nd attempt.
 */
const BASE_DELAY = 6000; // ms
const MAX_DELAY = 24000; // ms
const MAX_RETRIES = 3;

interface CompleteParams {
  invoiceId: string;
}

const CANADA_PROVINCES = [
  'Alberta',
  'British Columbia',
  'Manitoba',
  'New Brunswick',
  'Newfoundland and Labrador',
  'Northwest Territories',
  'Nova Scotia',
  'Nunavut',
  'Ontario',
  'Prince Edward Island',
  'Quebec',
  'Saskatchewan',
  'Yukon'
];

interface MessagePageProps {
  title: string;
  message: string;
  policyFoxdenId?: string;
}

const MessagePage: React.FC<MessagePageProps> = ({
  title,
  message,
  policyFoxdenId
}: MessagePageProps) => {
  return (
    <div className="m-auto text-lg text-center" data-testid="Completed">
      <div>
        <p>
          <b className="text-xl">{title}</b>
        </p>
        <p className="mt-4">{message}</p>
        {policyFoxdenId ? (
          <p>
            Your policy number is{' '}
            <span id="policyFoxdenId">{policyFoxdenId}</span>.
          </p>
        ) : null}
        <p>
          If you have any questions, contact us at{' '}
          <a href="tel:+18888784588" className="underline">
            1-888-878-4588
          </a>{' '}
          or{' '}
          <a href="mailto:support@foxquilt.com" className="underline">
            support@foxquilt.com
          </a>
          .
        </p>
      </div>
    </div>
  );
};

const InvoiceExpired: React.FC = () => (
  <div className="m-auto text-lg text-center">
    Sorry! Your quote has expired. Please contact us at{' '}
    <a href="tel:+18888784588" className="underline">
      1-888-878-4588
    </a>{' '}
    or{' '}
    <a href="mailto:sales@foxquilt.com" className="underline">
      sales@foxquilt.com
    </a>{' '}
    to complete your premium payment.
  </div>
);

interface PaymentCompleteMessageProps {
  isNotFound?: boolean;
  isExpired?: boolean;
  policyFoxdenId?: string;
}

export const PaymentCompleteMessage: React.FC<PaymentCompleteMessageProps> = ({
  isExpired,
  isNotFound,
  policyFoxdenId
}) => {
  if (isNotFound) {
    captureException(
      new Error('Unreachable: unknown GetInvoiceErrorKind status')
    );
    return <Redirect to="/404" />;
  }

  if (policyFoxdenId) {
    return (
      <MessagePage
        title={'Thank you for signing the Terms & Conditions Document!'}
        message={
          'You will receive in your inbox shortly: your payment receipt, policy documents and customer service support numbers.'
        }
        policyFoxdenId={policyFoxdenId}
      />
    );
  }

  if (isExpired) {
    return <InvoiceExpired />;
  }

  throw new Error('Unreachable: unsupported payment completion status type');
};

const Complete: React.FC = (props) => {
  useContactBar();
  const attempt = useRef(0);
  const propsObj = JSON.parse(JSON.stringify(props));
  const state = propsObj['history']['location']['state'];
  const [reachMaxRetries, setReachMaxRetries] = useState(false);

  const { invoiceId } = useParams<CompleteParams>();

  const [
    loadGetPaymentStatusQuery,
    { data, error, loading, called, refetch }
  ] = useGetPaymentStatusLazyQuery({
    variables: {
      invoiceId
    }
  });

  useEffect(() => {
    if (
      data?.getInvoice.__typename === 'GetInvoiceSuccess' &&
      data.getInvoice.invoiceInfo
    ) {
      const { invoiceInfo } = data.getInvoice;
      const { client } = invoiceInfo;

      if (client) {
        const { region } = client.address;
        const country = CANADA_PROVINCES.includes(region)
          ? 'Canada'
          : 'United States of America';
        const tagManagerArgs = {
          dataLayer: {
            event: 'form-progress',
            type: 'commercial',
            country,
            'state/province': region,
            step: 'Munich_Thankyou'
          }
        };
        TagManager.dataLayer(tagManagerArgs);
      }
    }

    const getPaymentStatusQueryWithRetry = async (delay: number) => {
      if (
        loading ||
        (data?.getInvoice.__typename === 'GetInvoiceSuccess' &&
          data.getInvoice.policyFoxdenId)
      ) {
        return;
      } else if (attempt.current >= MAX_RETRIES) {
        setReachMaxRetries(true);
        return;
      }
      /**
       * TODO: Delay before the first request to address issue NCP-1898.
       * This is a temporary solution.
       * If the first response returns {policyNumber: null, isPaid: false},
       * it still incorrectly redirects to the payment page.
       */
      // Wait for the calculated delay before retrying
      await sleep(delay);
      if (!called) {
        loadGetPaymentStatusQuery(); // This is the first time the API is called
        attempt.current++;
      } else if (refetch && called) {
        const { data: refetchData } = await refetch({
          invoiceId
        });
        attempt.current++;
        if (
          refetchData && // original data provided by useGetPaymentStatusLazyQuery will not be updated immediately, so need to check refetchData.
          refetchData.getInvoice.__typename === 'GetInvoiceSuccess' &&
          refetchData.getInvoice.policyFoxdenId
        ) {
          return;
        } else {
          delay = getDelay(delay, MAX_DELAY);
          await getPaymentStatusQueryWithRetry(delay);
        }
      }
    };

    getPaymentStatusQueryWithRetry(BASE_DELAY);
  }, [
    data,
    error,
    loading,
    refetch,
    called,
    invoiceId,
    loadGetPaymentStatusQuery
  ]);

  if (error) {
    return (
      <div className="m-auto text-lg text-center">
        Sorry, there&apos;s an error when we get payment status. {error.message}
      </div>
    );
  }

  if (loading || !called) {
    return <Loading />;
  }

  if (reachMaxRetries) {
    return (
      <div className="m-auto text-lg text-center">
        Sorry, there&apos;s an error after trying {MAX_RETRIES} times to get
        payment status.
      </div>
    );
  }

  if (!data) {
    return (
      <div className="m-auto text-lg text-center">
        Sorry, there&apos;s a network problem.
      </div>
    );
  }

  const { getInvoice } = data;

  switch (getInvoice.__typename) {
    case 'FoxdenError': {
      return (
        <MessagePage
          title={'There is an error'}
          message={
            'You will receive in your inbox shortly: your policy documents and customer service support numbers.'
          }
        />
      );
    }
    /** Invoice is found, but whether it's paid or not is a different question. */
    case 'GetInvoiceSuccess':
      const { isPaid, policyFoxdenId } = getInvoice;

      // well, you've the policy number, so obviously you've paid too.
      if (policyFoxdenId) {
        switch (state?.flowKind) {
          case 'RefundFullPaySuccess':
            return (
              <MessagePage
                title={
                  'Thank you for signing the Terms & Conditions Document! Your Refund will be issued in up to 5 business days.'
                }
                message={'Please check your inbox for all updated documents.'}
                policyFoxdenId={policyFoxdenId}
              />
            );
          case 'RefundInstallmentSuccess':
            return (
              <MessagePage
                title={
                  'Thank you for signing the Terms & Conditions Document! Your Refund will be issued in up to 5 business days.'
                }
                message={'Please check your inbox for all updated documents.'}
                policyFoxdenId={policyFoxdenId}
              />
            );
          case 'WaiveSuccess':
            return (
              <MessagePage
                title={'Thank you for signing the Terms & Conditions Document!'}
                message={'Please check your inbox for all updated documents.'}
                policyFoxdenId={policyFoxdenId}
              />
            );
          case 'CancellationSuccess':
            return (
              <MessagePage
                title={'Thank you for signing the Lost Policy Release!'}
                message={
                  'Your policy has been cancelled. Please check your inbox for all relevant documents.'
                }
                policyFoxdenId={policyFoxdenId}
              />
            );
          default:
            // for the initial purchase flow
            return (
              <MessagePage
                title={'Thank you for submitting your payment!'}
                message={
                  'You will receive in your inbox shortly: your payment receipt, policy documents and customer service support numbers.'
                }
                policyFoxdenId={policyFoxdenId}
              />
            );
        }
      }

      if (!isPaid) {
        return <Redirect to={`/payment/${invoiceId}`} />;
      }

      // i.e. the invoice is paid but the Policy isn't created yet (webhook should be running in parallel)
      return <Loading />;
    default:
      captureException(new Error('Unreachable: unknown getInvoice state'));
      return <Redirect to="/404" />;
  }
};

export const QuickComplete: React.FC = () => (
  <div className="m-auto text-lg text-center">
    <div>
      <p>
        <b className="text-xl">
          Thank you, your Policy details with Foxquilt have been updated.
        </b>
      </p>
      <p>
        If you have any questions, contact us at{' '}
        <a href="tel:+18888784588" className="underline">
          1-888-878-4588
        </a>{' '}
        or{' '}
        <a href="mailto:support@foxquilt.com" className="underline">
          support@foxquilt.com
        </a>
        .
      </p>
    </div>
  </div>
);

export default Complete;
