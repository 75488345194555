import React from 'react';

interface ModalProps {
  setClose?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  /**
   * Either:
   * - a normal even handler function
   * - or a string container a URL that the "Action" button links to
   * - or undefined
   */
  onAction?:
    | ((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | string;
  closeName?: string;
  actionName?: string;
  children?: React.ReactNode;
}

const Modal: React.FC<ModalProps> = (props) => {
  const { setClose, onAction, actionName, closeName } = props;

  const closeButton = setClose ? (
    <button
      onClick={setClose}
      className="px-12 py-3 text-base font-bold text-white bg-gray-500 rounded-md"
    >
      {closeName || 'CANCEL'}
    </button>
  ) : null;

  const actionLabel = actionName || 'NEXT';

  function createActionButton() {
    const BUTTON_CLASS =
      'px-12 py-3 text-base font-bold text-white rounded-md py-43 bg-secondary';

    switch (typeof onAction) {
      case 'string':
        return (
          <a href={onAction}>
            <button className={BUTTON_CLASS}>{actionLabel}</button>
          </a>
        );
      case 'function':
        return (
          <button onClick={onAction} className={BUTTON_CLASS}>
            {actionLabel}
          </button>
        );
      case 'undefined':
        return null;
      default:
        throw new Error('unsupported action');
    }
  }

  return (
    <div
      className="flex flex-col w-full min-h-full transition duration-200 ease-in-out bg-black bg-opacity-25"
      data-testid="Modal"
    >
      <div className="box-content w-3/4 pt-6 pb-3 m-auto text-center bg-white border border-gray-600 border-solid md:max-w-3xl font-base">
        {props.children}
        <div className="flex flex-col flex-col-reverse gap-6 sm:flex-row justify-center mb-1">
          <div>{closeButton}</div>
          <div>{createActionButton()}</div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
