import './PaymentOptions.css';

import React from 'react';

import { PaymentMethod } from '../../../generated/graphql';
import amexImg from '../images/amex.png';
import discover from '../images/discover.png';
import mastercardImg from '../images/mastercard.png';
import stripe from '../images/stripe.png';
import visaImg from '../images/visa.jpg';
import { OptionRadio } from './Radio/Radio';

function isPayNow(method?: PaymentMethod): boolean {
  return method === PaymentMethod.CreditCard;
}

interface PaymentOptionsProps {
  paymentMethod: PaymentMethod;
  setPaymentMethod: (method: PaymentMethod) => void;
}

const PaymentOptions: React.FC<PaymentOptionsProps> = ({
  paymentMethod,
  setPaymentMethod
}) => {
  // in this component these are 2 different kinds of radio groups that share the same range of values (PaymentMethod),
  // so only one event handler is needed to handle them.
  const handlePaymentOptionChange = (e: React.FormEvent<HTMLDivElement>) => {
    const method: PaymentMethod =
      PaymentMethod[
        (e.target as HTMLInputElement).value as keyof typeof PaymentMethod
      ];
    if (PaymentMethod[method] === undefined) {
      throw new Error(
        'bug: payment method cannot be retrieved from radio buttons'
      );
    }
    setPaymentMethod(method);
  };

  return (
    <div className="PaymentOptions m-2" data-testid="PaymentOptions">
      <table className="payment-options">
        <thead>
          <tr>
            <th className="uppercase text-left" colSpan={2}>
              Payment Options
            </th>
          </tr>
        </thead>
        <tbody>
          <tr onChange={handlePaymentOptionChange}>
            <td>
              <OptionRadio value={PaymentMethod.CreditCard} defaultChecked />
            </td>
            <td>Credit Card</td>
          </tr>
          {isPayNow(paymentMethod) ? (
            <tr>
              <td />
              <td>
                <div>
                  <div className="mt-2 uppercase">We accept:</div>
                  <div className="flex flex-wrap logo-container">
                    <img src={stripe} alt="stripe" />
                  </div>
                  <div className="flex flex-wrap logo-container">
                    <img src={visaImg} alt="Visa" />
                    <img src={mastercardImg} alt="Master Card" />
                    <img src={amexImg} alt="American Express" />
                  </div>
                  <div className="flex flex-wrap logo-container">
                    <img src={discover} alt="discover" />
                  </div>
                </div>
              </td>
            </tr>
          ) : null}
          <br></br>
        </tbody>
      </table>
    </div>
  );
};

export default PaymentOptions;
