import { withAuthenticationRequired } from '@auth0/auth0-react';
import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

import Loading from '../components/Loading';
import { useAuth } from './AuthProvider';

// copied from https://auth0.com/blog/complete-guide-to-react-user-authentication/

const ProtectedRoute: React.FC<RouteProps> = ({ component, ...args }) => {
  const { isAuthenticated: isAuthAuthenticated } = useAuth();

  if (!component) {
    throw new Error('Unreachable: component of ProtectedRoute is not set');
  }

  return (
    <Route
      component={
        isAuthAuthenticated
          ? component
          : withAuthenticationRequired(component, {
              onRedirecting: () => <Loading />
            })
      }
      {...args}
    />
  );
};

export default ProtectedRoute;
