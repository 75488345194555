import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useParams } from 'react-router-dom';

import { useAuth } from '../../auth/AuthProvider';
import { MessageLoading } from '../../components/Loading';
import Signout from '../../components/Signout';
import {
  useGenerateSigningUrlMutation,
  useGetApplicationbyQuoteIdLazyQuery
} from '../../generated/graphql';
import getEnv from '../../utils/getEnv';
import Error from '../Error';

const CLASS_NAME = 'Terms m-auto text-lg container text-center';

const {
  REACT_APP_API_MAX_RETRIES,
  REACT_APP_API_BASE_DELAY,
  REACT_APP_API_MAX_DELAY
} = getEnv();

interface TermsParams {
  quoteId: string;
}

const Terms: React.FC = () => {
  const [reachMaxRetries, setReachMaxRetries] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  let quoteId = queryParams.get('quote') ?? undefined;

  const { quoteId: tempQuoteId } = useParams<Partial<TermsParams>>();

  if (!quoteId) {
    quoteId = tempQuoteId;
  }

  const [
    generateSigningUrl,
    { error: signingUrlError }
  ] = useGenerateSigningUrlMutation();
  const [
    getApplicationbyQuoteId,
    { data: applicationData, error: getApplicationError }
  ] = useGetApplicationbyQuoteIdLazyQuery();
  const {
    user: authUser,
    isLoading: isAuthLoading,
    isAuthenticated: isAuthAuthenticated
  } = useAuth();
  const {
    user: auth0User,
    error: auth0Error,
    isLoading: isAuth0Loading
  } = useAuth0();
  const user = isAuthAuthenticated ? authUser : auth0User;
  const { email_verified, email } = user || {};
  const isEmailValid = email_verified && !!email;

  useEffect(() => {
    if (!isEmailValid) {
      return;
    }

    if (!quoteId) {
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps

    getApplicationbyQuoteId({
      variables: { quoteId }
    });

    const generateSigningUrlWithRetry = async (
      quoteId: string,
      attempt: number,
      maxRetries: number,
      delay: number,
      maxDelay: number
    ) => {
      try {
        const signingUrl = await generateSigningUrl({
          variables: { quoteId }
        });
        const ret = signingUrl.data?.generateSigningURL;
        if (!ret) {
          <Error message="Couldn't get the signing Url" />;
        }
        window.location.href = ret;
      } catch (error) {
        attempt++;

        if (attempt >= maxRetries) {
          setReachMaxRetries(true);
          throw error;
        }

        // Calculate the next retry delay with exponential backoff and jitter
        const jitter = Math.random() * delay;
        const exponentialBackoff = Math.min(delay * 2, maxDelay);
        delay = Math.min(exponentialBackoff + jitter, maxDelay);

        // Wait for the calculated delay before retrying
        await new Promise((resolve) => setTimeout(resolve, delay));

        generateSigningUrlWithRetry(
          quoteId,
          attempt,
          maxRetries,
          delay,
          maxDelay
        );

        return;
      }
    };

    if (applicationData && applicationData.getApplicationbyQuoteId) {
      const {
        getApplicationbyQuoteId: { country, state }
      } = applicationData;
      const tagManagerArgs = {
        dataLayer: {
          event: 'form-progress',
          type: 'commercial',
          country: country,
          'state/province': state,
          step: 'Munich_T&C'
        }
      };
      TagManager.dataLayer(tagManagerArgs);

      generateSigningUrlWithRetry(
        quoteId,
        0,
        Number(REACT_APP_API_MAX_RETRIES),
        Number(REACT_APP_API_BASE_DELAY),
        Number(REACT_APP_API_MAX_DELAY)
      );
    }
  }, [
    isEmailValid,
    quoteId,
    applicationData,
    generateSigningUrl,
    getApplicationbyQuoteId
  ]);

  if (signingUrlError && reachMaxRetries) {
    return <Error apolloError={signingUrlError} />;
  }

  if (getApplicationError) {
    return (
      <div className={CLASS_NAME}>
        There is a backend error: {getApplicationError.message}
      </div>
    );
  }

  if (auth0Error) {
    return (
      <div className={CLASS_NAME}>
        There is an Auth0 error: {auth0Error.message}
      </div>
    );
  }

  if (!isAuth0Loading && !isAuthLoading) {
    if (!user) {
      return (
        <div className={CLASS_NAME}>
          Cannot retrieve user profile from Auth0.
        </div>
      );
    }

    if (!isEmailValid) {
      return (
        <div className={`${CLASS_NAME} leading-loose`}>
          <div className="text-lg font-bold">Please keep this tab open.</div>
          We have sent the verification email. Please check your inbox and
          refresh this tab after completing email verification.
        </div>
      );
    }
  }

  return (
    <>
      <Signout />
      <MessageLoading
        headingMessage="Your patience is appreciated!"
        subHeadingMessage="We are creating the documents you'll need to review & sign"
      />
    </>
  );
};

export default Terms;
