import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AbbrevCanadaProvince: any;
  Answers: any;
  CanadaProvince: "Alberta" | "British Columbia" | "Manitoba" | "New Brunswick" | "Newfoundland and Labrador" | "Northwest Territories" | "Nova Scotia" | "Nunavut" | "Ontario" | "Prince Edward Island" | "Quebec" | "Saskatchewan" | "Yukon";
  CanadaTimeZone: any;
  ChangedQuoteObject: any;
  Date: string;
  EmailAddress: string;
  JSONObject: any;
  NonEmptyString: string;
  NonNegativeFloat: number;
  NonNegativeInt: number;
  ObjectID: string;
  PolicyDescription: "CGL" | "E&O" | "D&O" | "Crime" | "Cyber Liability" | "Legal Expense" | "Medical Malpractice";
  PositiveFloat: number;
  PostalCode: string;
  URL: any;
  USTimeZone: any;
  UUID: any;
};


export type Address = {
  __typename?: 'Address';
  street: Scalars['String'];
  city: Scalars['String'];
  region: Scalars['CanadaProvince'];
  postalCode: Scalars['PostalCode'];
};

export type AddressInput = {
  street: Scalars['String'];
  city: Scalars['String'];
  region: Scalars['CanadaProvince'];
  postalCode: Scalars['PostalCode'];
};


export type BusinessAddressChange = {
  __typename?: 'BusinessAddressChange';
  newAddress: UnderwritingAddress;
};

export type CalChangedQuoteQuoteInput = {
  objectId: Scalars['ObjectID'];
  results: CalChangedQuoteQuoteResultsInput;
};

export type CalChangedQuoteQuoteResultsInput = {
  calculations: MunichCalculationsInput;
  coverageStartDate: Scalars['Date'];
  coverageExpiryDate: Scalars['Date'];
};



export type CancellationQuoteResponse = {
  __typename?: 'CancellationQuoteResponse';
  quoteNumber: Scalars['String'];
  quoteExpireDate: Scalars['Date'];
  results: CancellationQuoteResults;
};

export type CancellationQuoteResults = {
  __typename?: 'CancellationQuoteResults';
  prevQuoteObjectId: Scalars['ObjectID'];
  /** Prorated premiums */
  calculations: MunichProratedCalculations;
  coverageStartDate: Scalars['Date'];
  coverageExpiryDate: Scalars['Date'];
};

export type CancellationSuccess = {
  __typename?: 'CancellationSuccess';
  result: Scalars['Boolean'];
};

export enum CancellationTrigger {
  Carrier = 'carrier',
  Client = 'client'
}


export type ChangedQuoteResponse = {
  __typename?: 'ChangedQuoteResponse';
  quoteNumber: Scalars['String'];
  quoteExpireDate: Scalars['Date'];
  results: ChangedQuoteResults;
};

export type ChangedQuoteResults = {
  __typename?: 'ChangedQuoteResults';
  prevQuoteObjectId: Scalars['ObjectID'];
  /** The quote result for the input answers */
  curQuoteResults: QuoteResults;
  /** Prorated premiums */
  calculations: MunichProratedCalculations;
  coverageStartDate: Scalars['Date'];
  coverageExpiryDate: Scalars['Date'];
};

export type ChargeSuccess = {
  __typename?: 'ChargeSuccess';
  getInvoiceDeciderResult: GetInvoiceSuccess;
  userPaymentPlanOptionDeciderResult: GetUserPaymentOptionSuccess;
};

export enum ChildCoverageType {
  /** Commercial General Liability */
  Cgl = 'CGL',
  /** Errors & Omissions */
  Eo = 'EO',
  /** Limited Pollution */
  LimitedPollution = 'LimitedPollution',
  /** Unmanned Aircraft */
  UnmannedAircraft = 'UnmannedAircraft',
  /** Unscheduled Business Personal PropertyP */
  UnscheduledBpp = 'UnscheduledBPP',
  /** Hardware / Software */
  Hwsw = 'HWSW',
  /** Lock Key Business Items */
  LockKeyBusinessItems = 'LockKeyBusinessItems',
  /** Unscheduled Contractors */
  UnscheduledContractors = 'UnscheduledContractors',
  /** Unscheduled hardware/software */
  UnscheduledHwsw = 'UnscheduledHWSW',
  /** Unscheduled Small Tools */
  UnscheduledSmallTools = 'UnscheduledSmallTools',
  /** Lock Key Contractors */
  LockKeyContractors = 'LockKeyContractors',
  /** Photo Video Endorsement */
  PhotoVideoEndor = 'PhotoVideoEndor',
  /** Physical Loss */
  PhysicalLoss = 'PhysicalLoss',
  /** Valuable Papers */
  ValuablePapers = 'ValuablePapers'
}

export type CoiAdditionalInsuredInput = {
  address: UnderwritingAddressInput;
  name: Scalars['NonEmptyString'];
};

export type CoverageLimit = {
  __typename?: 'CoverageLimit';
  coverage: ChildCoverageType;
  oldLimit?: Maybe<Scalars['NonNegativeFloat']>;
  newLimit?: Maybe<Scalars['NonNegativeFloat']>;
};

export type CoverageUpdateChange = {
  __typename?: 'CoverageUpdateChange';
  coverage: ParentCoverageType;
  limits: Array<CoverageLimit>;
};

export type CreatePaymentInput = {
  invoiceId: Scalars['ObjectID'];
  method: PaymentMethod;
  provider: Provider;
};

export type CreatePaymentSessionInput = {
  invoiceId: Scalars['ObjectID'];
  paymentPlanId: Scalars['NonNegativeFloat'];
  method: PaymentMethod;
  useCardForRenewals: Scalars['Boolean'];
};

export enum CreateRefundableSequenceErrorKind {
  /** No Valid PaymentResult under this Policy */
  NoValidPaymentResult = 'NoValidPaymentResult'
}

/** Currencies that are supported by Foxden. */
export enum Currency {
  Cad = 'CAD',
  Usd = 'USD'
}


export type DocumentDownloadLink = {
  __typename?: 'DocumentDownloadLink';
  policyDocument: Scalars['String'];
  COI: Scalars['String'];
  PaymentReceipt: Scalars['String'];
  T_CDocument: Scalars['String'];
};

export type DocumentInformation = {
  __typename?: 'DocumentInformation';
  documentDownloadLink: DocumentDownloadLink;
  policyDetails: PolicyDetail;
};


export type EndorsementChange = BusinessAddressChange | CoverageUpdateChange | MailingAddressChange;

export enum FlowKind {
  /** This means initial flow, not endorsement */
  Initial = 'Initial',
  /** This means in endorsement flow, we need to refund clients */
  RefundFullPay = 'RefundFullPay',
  /** This means in endorsement flow, we need to refund clients */
  RefundInstallment = 'RefundInstallment',
  /** This means in endorsement flow, we need to waive */
  Waive = 'Waive',
  /** This means in endorsement flow, we need to charge clients */
  Charge = 'Charge',
  Cancellation = 'Cancellation'
}

export type FoxdenError = {
  __typename?: 'FoxdenError';
  fileName: Scalars['String'];
  message: Scalars['String'];
};

export type GenerateOhioEndorsementSigningUrlResult = {
  __typename?: 'GenerateOhioEndorsementSigningURLResult';
  envelopeId: Scalars['UUID'];
  signingURL: Scalars['URL'];
};

export type GetFlowKindSuccess = {
  __typename?: 'GetFlowKindSuccess';
  flowKind: FlowKind;
  isPaid: Scalars['Boolean'];
  isExpired: Scalars['Boolean'];
  policyRemoval: PolicyRemoval;
  policyFoxdenId: Scalars['String'];
};

/** The error responses for getInvoice. Instead of revealing the data we just return a flag. */
export enum GetInvoiceErrorKind {
  /** This means invoiceId doesn't match our database. Record doesn't exist. */
  NotFound = 'NotFound'
}

export type GetInvoiceResults = GetInvoiceSuccess | FoxdenError;

export type GetInvoiceSuccess = {
  __typename?: 'GetInvoiceSuccess';
  invoiceInfo: InvoiceInfo;
  isPaid: Scalars['Boolean'];
  isExpired: Scalars['Boolean'];
  policyFoxdenId?: Maybe<Scalars['String']>;
  timeZone: Scalars['CanadaTimeZone'];
};

export type GetPolicyTaxServiceFeeRateResults = GetPolicyTaxServiceFeeRateSuccess | FoxdenError;

export type GetPolicyTaxServiceFeeRateSuccess = {
  __typename?: 'GetPolicyTaxServiceFeeRateSuccess';
  surchargeRate: Scalars['Float'];
  taxRate: Scalars['Float'];
  serviceFeeRate: Scalars['Float'];
  policyFeeRate: Scalars['Float'];
};

export type GetUniqueValueUnderPolicyNumberResults = FoxdenError | UniqueValueUnderPolicyNumber;

export type GetUserPaymentOptionSuccess = {
  __typename?: 'GetUserPaymentOptionSuccess';
  userPaymentPlanOption: Array<UserPaymentPlanOption>;
};

export enum IdType {
  Claim = 'Claim',
  Invoice = 'Invoice',
  Policy = 'Policy',
  Quote = 'Quote',
  Transaction = 'Transaction'
}

export type InitialSuccess = {
  __typename?: 'InitialSuccess';
  getInvoiceDeciderResult: GetInvoiceSuccess;
  userPaymentPlanOptionDeciderResult: GetUserPaymentOptionSuccess;
};

export enum InstallmentFrequency {
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
  Annual = 'Annual'
}

/** TODO: Update currency type, todo item is to replace currency with real credit card fee in backend */
export type InvoiceClient = {
  __typename?: 'InvoiceClient';
  address: Address;
};

export type InvoiceClientInput = {
  namedInsured: Scalars['NonEmptyString'];
  name: NameInput;
  contactNumber: Scalars['String'];
  email: Scalars['EmailAddress'];
  address: AddressInput;
};

export type InvoiceInfo = {
  __typename?: 'InvoiceInfo';
  invoiceNumber: Scalars['String'];
  invoiceDate: Scalars['Date'];
  quoteNumber: Scalars['String'];
  totalPremium: Scalars['Float'];
  glSurcharge: Scalars['Float'];
  imSurcharge: Scalars['Float'];
  surcharge: Scalars['Float'];
  tax: Scalars['Float'];
  policyFee: Scalars['Float'];
  currency: Scalars['String'];
  client?: Maybe<InvoiceClient>;
};

export type InvoiceInfoInput = {
  invoiceNumber: Scalars['String'];
  invoiceDate: Scalars['Date'];
  quoteNumber: Scalars['String'];
  totalPremium: Scalars['Float'];
  glSurcharge: Scalars['Float'];
  imSurcharge: Scalars['Float'];
  surcharge: Scalars['Float'];
  tax: Scalars['Float'];
  glPolicyFeeTax: Scalars['Float'];
  imPolicyFeeTax: Scalars['Float'];
  policyFeeTax: Scalars['Float'];
  glPolicyFee: Scalars['Float'];
  imPolicyFee: Scalars['Float'];
  policyFee: Scalars['Float'];
  client: InvoiceClientInput;
  policyDetails: InvoicePolicyDetailsInput;
};

export type InvoicePolicyDetailsInput = {
  effectiveDate: Scalars['Date'];
  description: Array<Scalars['PolicyDescription']>;
  notes: Scalars['String'];
};

export type IsExpired = {
  __typename?: 'IsExpired';
  isExpired: Scalars['Boolean'];
};

export type IsPaid = {
  __typename?: 'IsPaid';
  policyNumber: Scalars['String'];
};


export type MailingAddressChange = {
  __typename?: 'MailingAddressChange';
  newAddress: UnderwritingAddress;
};

export type MonerisData = {
  __typename?: 'MonerisData';
  hpp_id: Scalars['String'];
  ticket: Scalars['String'];
};

export type MonerisPreloadData = {
  __typename?: 'MonerisPreloadData';
  hpp_id: Scalars['String'];
  ticket: Scalars['String'];
};

export type MunichCalculations = {
  __typename?: 'MunichCalculations';
  GL: MunichGlCalculations;
  IM: MunichImCalculations;
  totalPremium: Scalars['NonNegativeFloat'];
};

export type MunichCalculationsInput = {
  GL: MunichGlCalculationsInput;
  IM: MunichImCalculationsInput;
  totalPremium: Scalars['NonNegativeFloat'];
};

export type MunichGlAggregations = {
  __typename?: 'MunichGLAggregations';
  /** This is the premium for the required coverage. */
  basePremium: Scalars['NonNegativeFloat'];
  /** This is the premium including all additional coverages. */
  totalPremium: Scalars['NonNegativeFloat'];
  pollutionPremium: Scalars['NonNegativeFloat'];
  unmannedAircraftPremium: Scalars['NonNegativeFloat'];
  eoPremium: Scalars['NonNegativeFloat'];
  abuseAndMolestationPremium: Scalars['NonNegativeFloat'];
};

export type MunichGlAggregationsInput = {
  /** This is the premium for the required coverage. */
  basePremium: Scalars['NonNegativeFloat'];
  /** This is the premium including all additional coverages. */
  totalPremium: Scalars['NonNegativeFloat'];
  pollutionPremium: Scalars['NonNegativeFloat'];
  unmannedAircraftPremium: Scalars['NonNegativeFloat'];
  eoPremium: Scalars['NonNegativeFloat'];
  abuseAndMolestationPremium: Scalars['NonNegativeFloat'];
};

export type MunichGlCalculations = {
  __typename?: 'MunichGLCalculations';
  labels: MunichGlRateLabels;
  aggregations: MunichGlAggregations;
};

export type MunichGlCalculationsInput = {
  labels: MunichGlRateLabelsInput;
  aggregations: MunichGlAggregationsInput;
};

export type MunichGlProratedAggregations = {
  __typename?: 'MunichGLProratedAggregations';
  /** This is the premium for the required coverage. */
  basePremium: Scalars['Float'];
  /** This is the premium including all additional coverages. */
  totalPremium: Scalars['Float'];
  pollutionPremium: Scalars['Float'];
  unmannedAircraftPremium: Scalars['Float'];
  eoPremium: Scalars['Float'];
  abuseAndMolestationPremium: Scalars['Float'];
};

export type MunichGlProratedCalculations = {
  __typename?: 'MunichGLProratedCalculations';
  labels: MunichGlProratedRateLabels;
  aggregations: MunichGlProratedAggregations;
};

export type MunichGlProratedRateLabels = {
  __typename?: 'MunichGLProratedRateLabels';
  cglPremPremium: Scalars['NonNegativeFloat'];
  cglProdPremium: Scalars['NonNegativeFloat'];
  cglMiniAdditionalPremium: Scalars['NonNegativeFloat'];
  cglAdditionalLimitsPremium: Scalars['NonNegativeFloat'];
};

export type MunichGlRateLabels = {
  __typename?: 'MunichGLRateLabels';
  /** TODO: types can be more specific */
  cglPremLabels: Scalars['JSONObject'];
  cglProdLabels: Scalars['JSONObject'];
  cglMeetMinLabels: Scalars['JSONObject'];
  cglAdditionalLimitsLabels: Scalars['JSONObject'];
  pollutionLiabLabels: Scalars['JSONObject'];
  unmannedAircraftLabels: Scalars['JSONObject'];
  eoLabels: Scalars['JSONObject'];
  abuseMolestationLabels: Scalars['JSONObject'];
  /** We can remove these fields or keep these, they are already in the sub labels */
  cglPremPremium: Scalars['NonNegativeFloat'];
  cglProdPremium: Scalars['NonNegativeFloat'];
  cglMiniAdditionalPremium: Scalars['NonNegativeFloat'];
  cglAdditionalLimitsPremium: Scalars['NonNegativeFloat'];
};

export type MunichGlRateLabelsInput = {
  /** TODO: types can be more specific */
  cglPremLabels: Scalars['JSONObject'];
  cglProdLabels: Scalars['JSONObject'];
  cglMeetMinLabels: Scalars['JSONObject'];
  cglAdditionalLimitsLabels: Scalars['JSONObject'];
  pollutionLiabLabels: Scalars['JSONObject'];
  unmannedAircraftLabels: Scalars['JSONObject'];
  eoLabels: Scalars['JSONObject'];
  abuseMolestationLabels: Scalars['JSONObject'];
  /** We can remove these fields or keep these, they are already in the sub labels */
  cglPremPremium: Scalars['NonNegativeFloat'];
  cglProdPremium: Scalars['NonNegativeFloat'];
  cglMiniAdditionalPremium: Scalars['NonNegativeFloat'];
  cglAdditionalLimitsPremium: Scalars['NonNegativeFloat'];
};

export type MunichImAggregations = {
  __typename?: 'MunichIMAggregations';
  /** This is the premium for the required coverage. */
  basePremium: Scalars['NonNegativeFloat'];
  /** It's the total premium (adjusted with minimum premium) - premiums for additional coverages */
  adjustBasePremium: Scalars['NonNegativeFloat'];
  /** This is the premium including all additional coverages. */
  totalPremium: Scalars['NonNegativeFloat'];
  /** Additional Endorsements */
  photographyAndVideographyPremium: Scalars['NonNegativeFloat'];
  /** Additional Endorsements */
  valualePapersPremium: Scalars['NonNegativeFloat'];
  /** Additional Endorsements */
  physicalLossPremium: Scalars['NonNegativeFloat'];
};

export type MunichImAggregationsInput = {
  /** This is the premium for the required coverage. */
  basePremium: Scalars['NonNegativeFloat'];
  /** It's the total premium (adjusted with minimum premium) - premiums for additional coverages */
  adjustBasePremium: Scalars['NonNegativeFloat'];
  /** This is the premium including all additional coverages. */
  totalPremium: Scalars['NonNegativeFloat'];
  /** Additional Endorsements */
  photographyAndVideographyPremium: Scalars['NonNegativeFloat'];
  /** Additional Endorsements */
  valualePapersPremium: Scalars['NonNegativeFloat'];
  /** Additional Endorsements */
  physicalLossPremium: Scalars['NonNegativeFloat'];
};

export type MunichImCalculations = {
  __typename?: 'MunichIMCalculations';
  labels: MunichImRateLabels;
  aggregations: MunichImAggregations;
};

export type MunichImCalculationsInput = {
  labels: MunichImRateLabelsInput;
  aggregations: MunichImAggregationsInput;
};

export type MunichImProratedAggregations = {
  __typename?: 'MunichIMProratedAggregations';
  /** It's the total premium (adjusted with minimum premium) - premiums for additional coverages */
  adjustBasePremium: Scalars['Float'];
  /** This is the premium for the required coverage. */
  basePremium: Scalars['Float'];
  /** This is the premium including all additional coverages. */
  totalPremium: Scalars['Float'];
  /** Additional Endorsements */
  photographyAndVideographyPremium: Scalars['Float'];
  /** Additional Endorsements */
  valualePapersPremium: Scalars['Float'];
  /** Additional Endorsements */
  physicalLossPremium: Scalars['Float'];
};

export type MunichImProratedCalculations = {
  __typename?: 'MunichIMProratedCalculations';
  labels: MunichImProratedRateLabels;
  aggregations: MunichImProratedAggregations;
};

export type MunichImProratedRateLabels = {
  __typename?: 'MunichIMProratedRateLabels';
  miniPremium: Scalars['NonNegativeFloat'];
  miniAddPremium: Scalars['NonNegativeFloat'];
  /** For contractors */
  unscheduledContractorsEquipmentPremium: Scalars['NonNegativeFloat'];
  /** For contractors */
  unscheduledHardwareAndSoftwarePremium: Scalars['NonNegativeFloat'];
  /** For contractors */
  unscheduledSmallToolsPremium: Scalars['NonNegativeFloat'];
  /** For contractors */
  contractorsLockAndKeyPremium: Scalars['NonNegativeFloat'];
  /** For non-contractors */
  unscheduledBPPPremium: Scalars['NonNegativeFloat'];
  /** For non-contractors */
  hardwareAndSoftwarePremium: Scalars['NonNegativeFloat'];
  /** For non-contractors */
  lockAndKeyPremium: Scalars['NonNegativeFloat'];
};

export type MunichImRateLabels = {
  __typename?: 'MunichIMRateLabels';
  unscheduledContractorsEquipmentLebels: Scalars['JSONObject'];
  unscheduledHardwareAndSoftwareLabels: Scalars['JSONObject'];
  unscheduledSmallToolsLebels: Scalars['JSONObject'];
  contractorsLockAndKeyLabels: Scalars['JSONObject'];
  unscheduledBPPLabels: Scalars['JSONObject'];
  hardwareAndSoftwareLabels: Scalars['JSONObject'];
  lockAndKeyLabels: Scalars['JSONObject'];
  photographyAndVideographyLebels: Scalars['JSONObject'];
  valualePapersLebels: Scalars['JSONObject'];
  physicalLossLabels: Scalars['JSONObject'];
  miniPremium: Scalars['NonNegativeFloat'];
  miniAddPremium: Scalars['NonNegativeFloat'];
  /** For contractors */
  unscheduledContractorsEquipmentPremium: Scalars['NonNegativeFloat'];
  /** For contractors */
  unscheduledHardwareAndSoftwarePremium: Scalars['NonNegativeFloat'];
  /** For contractors */
  unscheduledSmallToolsPremium: Scalars['NonNegativeFloat'];
  /** For contractors */
  contractorsLockAndKeyPremium: Scalars['NonNegativeFloat'];
  /** For non-contractors */
  unscheduledBPPPremium: Scalars['NonNegativeFloat'];
  /** For non-contractors */
  hardwareAndSoftwarePremium: Scalars['NonNegativeFloat'];
  /** For non-contractors */
  lockAndKeyPremium: Scalars['NonNegativeFloat'];
};

export type MunichImRateLabelsInput = {
  unscheduledContractorsEquipmentLebels: Scalars['JSONObject'];
  unscheduledHardwareAndSoftwareLabels: Scalars['JSONObject'];
  unscheduledSmallToolsLebels: Scalars['JSONObject'];
  contractorsLockAndKeyLabels: Scalars['JSONObject'];
  unscheduledBPPLabels: Scalars['JSONObject'];
  hardwareAndSoftwareLabels: Scalars['JSONObject'];
  lockAndKeyLabels: Scalars['JSONObject'];
  photographyAndVideographyLebels: Scalars['JSONObject'];
  valualePapersLebels: Scalars['JSONObject'];
  physicalLossLabels: Scalars['JSONObject'];
  miniPremium: Scalars['NonNegativeFloat'];
  miniAddPremium: Scalars['NonNegativeFloat'];
  /** For contractors */
  unscheduledContractorsEquipmentPremium: Scalars['NonNegativeFloat'];
  /** For contractors */
  unscheduledHardwareAndSoftwarePremium: Scalars['NonNegativeFloat'];
  /** For contractors */
  unscheduledSmallToolsPremium: Scalars['NonNegativeFloat'];
  /** For contractors */
  contractorsLockAndKeyPremium: Scalars['NonNegativeFloat'];
  /** For non-contractors */
  unscheduledBPPPremium: Scalars['NonNegativeFloat'];
  /** For non-contractors */
  hardwareAndSoftwarePremium: Scalars['NonNegativeFloat'];
  /** For non-contractors */
  lockAndKeyPremium: Scalars['NonNegativeFloat'];
};

export type MunichProratedCalculations = {
  __typename?: 'MunichProratedCalculations';
  GL: MunichGlProratedCalculations;
  IM: MunichImProratedCalculations;
  prorateFactor: Scalars['NonNegativeFloat'];
  newCharge: Scalars['Float'];
  totalPremium: Scalars['NonNegativeFloat'];
};

export type Mutation = {
  __typename?: 'Mutation';
  noOp?: Maybe<Scalars['Boolean']>;
  sendCOI: Scalars['Boolean'];
  storeInvoiceInfo: Scalars['ObjectID'];
  monerisCreatePayment: MonerisPreloadData;
  createPaymentSession: SessionData;
  completeRefund: Scalars['Boolean'];
  cancelationRefund: Scalars['Boolean'];
  saveInvoice: Scalars['URL'];
  generateSigningURL: Scalars['URL'];
  generateOhioEndorsementSigningURL: GenerateOhioEndorsementSigningUrlResult;
};


export type MutationSendCoiArgs = {
  country: Scalars['String'];
  policyFoxdenId: Scalars['String'];
  additionalInsured: CoiAdditionalInsuredInput;
};


export type MutationStoreInvoiceInfoArgs = {
  invoice: InvoiceInfoInput;
};


export type MutationMonerisCreatePaymentArgs = {
  paymentInfo: CreatePaymentInput;
};


export type MutationCreatePaymentSessionArgs = {
  paymentInfo: CreatePaymentSessionInput;
};


export type MutationCompleteRefundArgs = {
  creditMemoObjectId: Scalars['ObjectID'];
};


export type MutationCancelationRefundArgs = {
  invoiceId: Scalars['ObjectID'];
};


export type MutationSaveInvoiceArgs = {
  quoteId: Scalars['ObjectID'];
};


export type MutationGenerateSigningUrlArgs = {
  quoteId: Scalars['ObjectID'];
};


export type MutationGenerateOhioEndorsementSigningUrlArgs = {
  jsonFileName: Scalars['String'];
  policyObjectId: Scalars['ObjectID'];
  profession: Scalars['String'];
  quote: Scalars['ChangedQuoteObject'];
  signerClientId: Scalars['String'];
  signerEmail: Scalars['EmailAddress'];
  timeZone: Scalars['CanadaTimeZone'];
};

export type Name = {
  __typename?: 'Name';
  first: Scalars['String'];
  middle: Scalars['String'];
  last: Scalars['String'];
};

export type NameInput = {
  first: Scalars['String'];
  middle: Scalars['String'];
  last: Scalars['String'];
};





export enum ParentCoverageType {
  /** Commercial General Liability */
  Cgl = 'CGL',
  /** Inland Marine */
  Im = 'IM'
}

export enum PaymentMethod {
  CreditCard = 'CreditCard'
}

export type PaymentRouteDeciderResults = IsPaid | IsExpired | InitialSuccess | RefundFullPaySuccess | RefundInstallmentSuccess | WaiveSuccess | ChargeSuccess | CancellationSuccess | FoxdenError;

export enum PolicyCancelReason {
  /** Business Closed */
  BusinessClosed = 'BusinessClosed',
  /** Found Better Pricing Elsewhere */
  FoundBetterPricingElsewhere = 'FoundBetterPricingElsewhere',
  /** Insurance Is No Longer Required */
  InsuranceIsNoLongerRequired = 'InsuranceIsNoLongerRequired',
  /** Business Sold */
  BusinessSold = 'BusinessSold',
  /** Other */
  Other = 'Other'
}


export type PolicyDetail = {
  __typename?: 'PolicyDetail';
  timeZone: Scalars['CanadaTimeZone'];
  namedInsured: Scalars['String'];
  mailingAddress: UnderwritingAddress;
  insuredLocation: UnderwritingAddress;
  policyStartDate: Scalars['Date'];
  policyEndDate: Scalars['Date'];
  operations: Array<Scalars['String']>;
  country: Scalars['String'];
};

export type PolicyRemoval = {
  __typename?: 'PolicyRemoval';
  trigger?: Maybe<Scalars['String']>;
  removal?: Maybe<Scalars['String']>;
  stateOrProvince?: Maybe<Scalars['String']>;
  premium?: Maybe<Premium>;
  waivedPremium?: Maybe<WaivedPremium>;
};



export type Premium = {
  __typename?: 'Premium';
  imRefundPremium: Scalars['Float'];
  glRefundPremium: Scalars['Float'];
  imEarnedPremium: Scalars['Float'];
  glEarnedPremium: Scalars['Float'];
};

export enum Provider {
  Moneris = 'Moneris',
  Stripe = 'Stripe'
}

export type Query = {
  __typename?: 'Query';
  noOp?: Maybe<Scalars['Boolean']>;
  getAuth0UserPolicies: Array<Scalars['String']>;
  getDocumentInformation: DocumentInformation;
  /** This API only returns the invoice only if there's an associated complete payment. */
  getInvoice: GetInvoiceResults;
  isInvoiceNumberRegistered: Scalars['Boolean'];
  paymentRouteDecider: PaymentRouteDeciderResults;
  getPolicyTaxServiceFeeRate: GetPolicyTaxServiceFeeRateResults;
  getUserPaymentPlanOption: Array<UserPaymentPlanOption>;
  getUserPaymentPlanOptionByQuoteId: Array<UserPaymentPlanOptionByQuoteId>;
  getEmailAddressByQuoteId: Scalars['String'];
  getUniqueValueUnderPolicyNumber: GetUniqueValueUnderPolicyNumberResults;
  generateTnCDocument?: Maybe<Scalars['String']>;
  getApplicationbyQuoteId: GetApplicationbyQuoteIdResponse;
};


export type QueryGetDocumentInformationArgs = {
  policyId: Scalars['String'];
};


export type QueryGetInvoiceArgs = {
  invoiceId: Scalars['ObjectID'];
  placeholder?: Maybe<Scalars['JSONObject']>;
};


export type QueryIsInvoiceNumberRegisteredArgs = {
  invoiceNumber: Scalars['String'];
};


export type QueryPaymentRouteDeciderArgs = {
  invoiceId: Scalars['ObjectID'];
};


export type QueryGetPolicyTaxServiceFeeRateArgs = {
  provinceOrState: Scalars['String'];
  country: Scalars['String'];
};


export type QueryGetUserPaymentPlanOptionArgs = {
  InvoiceId: Scalars['ObjectID'];
};


export type QueryGetUserPaymentPlanOptionByQuoteIdArgs = {
  quoteId: Scalars['ObjectID'];
};


export type QueryGetEmailAddressByQuoteIdArgs = {
  quoteId: Scalars['ObjectID'];
};


export type QueryGetUniqueValueUnderPolicyNumberArgs = {
  policyNumber: Scalars['String'];
};


export type QueryGenerateTnCDocumentArgs = {
  answers: Scalars['JSONObject'];
  jsonFileName: Scalars['String'];
  country: Scalars['String'];
  rating: Scalars['JSONObject'];
  carrierPartner: Scalars['String'];
};


export type QueryGetApplicationbyQuoteIdArgs = {
  quoteId: Scalars['ObjectID'];
};

export type QuoteResponse = {
  __typename?: 'QuoteResponse';
  quoteId: Scalars['ObjectID'];
  premium: Scalars['Float'];
  quoteNumber: Scalars['String'];
};

/** This isn't used in the Graphql API; used for generating Typescript type. */
export type QuoteResults = {
  __typename?: 'QuoteResults';
  calculations: MunichCalculations;
  ratingInput: Scalars['JSONObject'];
  coverageStartDate: Scalars['Date'];
  coverageExpiryDate: Scalars['Date'];
};

export type QuoteResultsInput = {
  calculations: MunichCalculationsInput;
  ratingInput: Scalars['JSONObject'];
  coverageStartDate: Scalars['Date'];
  coverageExpiryDate: Scalars['Date'];
};

export type RefundFullPaySuccess = {
  __typename?: 'RefundFullPaySuccess';
  refundedSequence: Array<RefundableSequenceItem>;
};

export type RefundInstallmentSuccess = {
  __typename?: 'RefundInstallmentSuccess';
  result: Scalars['Boolean'];
};

export type RefundableSequenceItem = {
  __typename?: 'RefundableSequenceItem';
  timestamp: Scalars['Date'];
  paymentIntentId: Scalars['String'];
  invoiceId: Scalars['ObjectID'];
  refundableAmount: Scalars['NonNegativeFloat'];
};

export type SessionData = StripeData | MonerisData;

export type StripeData = {
  __typename?: 'StripeData';
  session_id: Scalars['String'];
  stripePlatformAccount: Scalars['String'];
};


export enum UsCancellationMethod {
  /** Flat */
  Flat = 'Flat',
  /** Short Rate */
  ShortRate = 'ShortRate',
  /** Pro Rata */
  ProRata = 'ProRata'
}

export enum UsPolicyCancelReason {
  /** Not Taken */
  NotTaken = 'NotTaken',
  /** Requested by Insured */
  RequestByInsured = 'RequestByInsured',
  /** Rewritten */
  Rewritten = 'Rewritten',
  /** Other */
  Other = 'Other'
}

export enum UsRequestDistribution {
  Insured = 'Insured',
  Mortgagee = 'Mortgagee',
  Company = 'Company',
  LossPayee = 'LossPayee',
  Lienholder = 'Lienholder',
  FinanceCompany = 'FinanceCompany',
  LenderLossPayable = 'LenderLossPayable'
}



export type UnderwritingAddress = {
  __typename?: 'UnderwritingAddress';
  street: Scalars['String'];
  city: Scalars['String'];
  province: Scalars['AbbrevCanadaProvince'];
  postalCode: Scalars['PostalCode'];
};

export type UnderwritingAddressInput = {
  street: Scalars['String'];
  city: Scalars['String'];
  province: Scalars['AbbrevCanadaProvince'];
  postalCode: Scalars['PostalCode'];
};

export type UniqueValueUnderPolicyNumber = {
  __typename?: 'UniqueValueUnderPolicyNumber';
  subscriptionScheduleId: Scalars['String'];
};

/** Needs to send this URL to customer for signing the Terms & Conditions before the endorsement can take effect. */
export type UpdatePolicyProceedToTerms = {
  __typename?: 'UpdatePolicyProceedToTerms';
  /** The ObjectID for the temporary policy object created by this operation. */
  insertedObjectId: Scalars['ObjectID'];
  /** URL to Docusign page. */
  url: Scalars['URL'];
  /** Does this endorsement possibly require payment or refund */
  isInvolvePayment: Scalars['Boolean'];
  /** The new charge */
  newCharge?: Maybe<Scalars['Float']>;
};

export type UserPaymentPlanOption = {
  __typename?: 'UserPaymentPlanOption';
  paymentPlanId: Scalars['NonNegativeFloat'];
  paymentPlan: Scalars['String'];
  invoiceNumber: Scalars['String'];
  invoiceDate: Scalars['Date'];
  quoteNumber: Scalars['String'];
  totalPremiumAmount: Scalars['NonNegativeFloat'];
  totalSurcharge: Scalars['NonNegativeFloat'];
  totalTaxes: Scalars['NonNegativeFloat'];
  totalPolicyFees: Scalars['NonNegativeFloat'];
  totalServiceFees: Scalars['NonNegativeFloat'];
  totalPayable: Scalars['NonNegativeFloat'];
  policyInceptionDate: Scalars['Date'];
  downPayment: Scalars['NonNegativeFloat'];
  numberOfInstallments: Scalars['NonNegativeFloat'];
  installmentFrequency: Scalars['String'];
  installmentAmount: Scalars['NonNegativeFloat'];
  paymentSchedule: Array<Maybe<Scalars['Date']>>;
};

export type UserPaymentPlanOptionByQuoteId = {
  __typename?: 'UserPaymentPlanOptionByQuoteID';
  paymentPlanId: Scalars['NonNegativeFloat'];
  paymentPlan: Scalars['String'];
  totalPremiumAmount: Scalars['NonNegativeFloat'];
  totalTaxes: Scalars['NonNegativeFloat'];
  totalPolicyFees: Scalars['NonNegativeFloat'];
  totalServiceFees: Scalars['NonNegativeFloat'];
  totalPayable: Scalars['NonNegativeFloat'];
  downPayment: Scalars['NonNegativeFloat'];
  numberOfInstallments: Scalars['NonNegativeFloat'];
  installmentFrequency: Scalars['String'];
  installmentAmount: Scalars['NonNegativeFloat'];
  surcharge: Scalars['Float'];
};

export type ValidateEndorsementResult = {
  __typename?: 'ValidateEndorsementResult';
  changes: Array<EndorsementChange>;
  effectiveDate: Scalars['Date'];
  isInvolvePayment: Scalars['Boolean'];
};

export type WaiveSuccess = {
  __typename?: 'WaiveSuccess';
  result: Scalars['Boolean'];
};

export type WaivedPremium = {
  __typename?: 'WaivedPremium';
  IMForUs?: Maybe<Scalars['Float']>;
  GLForUs?: Maybe<Scalars['Float']>;
  Canada?: Maybe<Scalars['Float']>;
};

export type GetApplicationbyQuoteIdResponse = {
  __typename?: 'getApplicationbyQuoteIdResponse';
  country: Scalars['String'];
  state: Scalars['String'];
};

export type GetEmailAddressByQuoteIdQueryVariables = Exact<{
  quoteId: Scalars['ObjectID'];
}>;


export type GetEmailAddressByQuoteIdQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getEmailAddressByQuoteId'>
);

export type CreatePaymentSessionMutationVariables = Exact<{
  paymentInfo: CreatePaymentSessionInput;
}>;


export type CreatePaymentSessionMutation = (
  { __typename?: 'Mutation' }
  & { createPaymentSession: (
    { __typename?: 'StripeData' }
    & Pick<StripeData, 'session_id' | 'stripePlatformAccount'>
  ) | (
    { __typename?: 'MonerisData' }
    & Pick<MonerisData, 'hpp_id' | 'ticket'>
  ) }
);

export type GetApplicationbyQuoteIdQueryVariables = Exact<{
  quoteId: Scalars['ObjectID'];
}>;


export type GetApplicationbyQuoteIdQuery = (
  { __typename?: 'Query' }
  & { getApplicationbyQuoteId: (
    { __typename?: 'getApplicationbyQuoteIdResponse' }
    & Pick<GetApplicationbyQuoteIdResponse, 'country' | 'state'>
  ) }
);

export type GetDocumentInformationQueryVariables = Exact<{
  policyId: Scalars['String'];
}>;


export type GetDocumentInformationQuery = (
  { __typename?: 'Query' }
  & { getDocumentInformation: (
    { __typename?: 'DocumentInformation' }
    & { documentDownloadLink: (
      { __typename?: 'DocumentDownloadLink' }
      & Pick<DocumentDownloadLink, 'COI' | 'PaymentReceipt' | 'T_CDocument' | 'policyDocument'>
    ), policyDetails: (
      { __typename?: 'PolicyDetail' }
      & Pick<PolicyDetail, 'namedInsured' | 'operations' | 'policyEndDate' | 'policyStartDate' | 'timeZone' | 'country'>
      & { insuredLocation: (
        { __typename?: 'UnderwritingAddress' }
        & Pick<UnderwritingAddress, 'city' | 'postalCode' | 'province' | 'street'>
      ), mailingAddress: (
        { __typename?: 'UnderwritingAddress' }
        & Pick<UnderwritingAddress, 'city' | 'postalCode' | 'province' | 'street'>
      ) }
    ) }
  ) }
);

export type GetAuth0UserPoliciesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAuth0UserPoliciesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getAuth0UserPolicies'>
);

export type GetPaymentPlanOptionQueryVariables = Exact<{
  InvoiceId: Scalars['ObjectID'];
}>;


export type GetPaymentPlanOptionQuery = (
  { __typename?: 'Query' }
  & { getUserPaymentPlanOption: Array<(
    { __typename?: 'UserPaymentPlanOption' }
    & Pick<UserPaymentPlanOption, 'paymentPlanId' | 'paymentPlan' | 'invoiceNumber' | 'invoiceDate' | 'quoteNumber' | 'totalPremiumAmount' | 'totalSurcharge' | 'totalTaxes' | 'totalPolicyFees' | 'totalServiceFees' | 'totalPayable' | 'policyInceptionDate' | 'downPayment' | 'numberOfInstallments' | 'installmentFrequency' | 'installmentAmount' | 'paymentSchedule'>
  )> }
);

export type MonerisCreatePaymentMutationVariables = Exact<{
  paymentInfo: CreatePaymentInput;
}>;


export type MonerisCreatePaymentMutation = (
  { __typename?: 'Mutation' }
  & { monerisCreatePayment: (
    { __typename?: 'MonerisPreloadData' }
    & Pick<MonerisPreloadData, 'hpp_id' | 'ticket'>
  ) }
);

export type StoreInvoiceInfoMutationVariables = Exact<{
  invoice: InvoiceInfoInput;
}>;


export type StoreInvoiceInfoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'storeInvoiceInfo'>
);

export type SendCoiMutationVariables = Exact<{
  additionalInsured: CoiAdditionalInsuredInput;
  policyFoxdenId: Scalars['String'];
  country: Scalars['String'];
}>;


export type SendCoiMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendCOI'>
);

export type GetPaymentStatusQueryVariables = Exact<{
  invoiceId: Scalars['ObjectID'];
  placeholder?: Maybe<Scalars['JSONObject']>;
}>;


export type GetPaymentStatusQuery = (
  { __typename?: 'Query' }
  & { getInvoice: (
    { __typename: 'GetInvoiceSuccess' }
    & Pick<GetInvoiceSuccess, 'isPaid' | 'policyFoxdenId'>
    & { invoiceInfo: (
      { __typename?: 'InvoiceInfo' }
      & { client?: Maybe<(
        { __typename?: 'InvoiceClient' }
        & { address: (
          { __typename?: 'Address' }
          & Pick<Address, 'city' | 'postalCode' | 'street' | 'region'>
        ) }
      )> }
    ) }
  ) | (
    { __typename: 'FoxdenError' }
    & Pick<FoxdenError, 'fileName' | 'message'>
  ) }
);

export type InitiatePaymentGetInvoiceQueryVariables = Exact<{
  invoiceId: Scalars['ObjectID'];
}>;


export type InitiatePaymentGetInvoiceQuery = (
  { __typename?: 'Query' }
  & { getInvoice: (
    { __typename: 'GetInvoiceSuccess' }
    & Pick<GetInvoiceSuccess, 'isExpired' | 'isPaid' | 'policyFoxdenId'>
    & { invoiceInfo: (
      { __typename?: 'InvoiceInfo' }
      & Pick<InvoiceInfo, 'invoiceDate' | 'invoiceNumber' | 'quoteNumber' | 'tax' | 'totalPremium' | 'policyFee' | 'currency'>
      & { client?: Maybe<(
        { __typename?: 'InvoiceClient' }
        & { address: (
          { __typename?: 'Address' }
          & Pick<Address, 'city' | 'postalCode' | 'street' | 'region'>
        ) }
      )> }
    ) }
  ) | (
    { __typename: 'FoxdenError' }
    & Pick<FoxdenError, 'fileName' | 'message'>
  ) }
);

export type GetPaymentRouteDeciderQueryVariables = Exact<{
  invoiceId: Scalars['ObjectID'];
}>;


export type GetPaymentRouteDeciderQuery = (
  { __typename?: 'Query' }
  & { paymentRouteDecider: (
    { __typename: 'IsPaid' }
    & Pick<IsPaid, 'policyNumber'>
  ) | (
    { __typename: 'IsExpired' }
    & Pick<IsExpired, 'isExpired'>
  ) | (
    { __typename: 'InitialSuccess' }
    & { getInvoiceDeciderResult: (
      { __typename?: 'GetInvoiceSuccess' }
      & Pick<GetInvoiceSuccess, 'isPaid' | 'isExpired' | 'policyFoxdenId' | 'timeZone'>
      & { invoiceInfo: (
        { __typename?: 'InvoiceInfo' }
        & Pick<InvoiceInfo, 'invoiceNumber' | 'invoiceDate' | 'quoteNumber' | 'totalPremium' | 'tax' | 'policyFee' | 'currency'>
        & { client?: Maybe<(
          { __typename?: 'InvoiceClient' }
          & { address: (
            { __typename?: 'Address' }
            & Pick<Address, 'city' | 'postalCode' | 'street' | 'region'>
          ) }
        )> }
      ) }
    ), userPaymentPlanOptionDeciderResult: (
      { __typename?: 'GetUserPaymentOptionSuccess' }
      & { userPaymentPlanOption: Array<(
        { __typename?: 'UserPaymentPlanOption' }
        & Pick<UserPaymentPlanOption, 'paymentPlanId' | 'paymentPlan' | 'invoiceNumber' | 'invoiceDate' | 'quoteNumber' | 'totalPremiumAmount' | 'totalSurcharge' | 'totalTaxes' | 'totalPolicyFees' | 'totalServiceFees' | 'totalPayable' | 'policyInceptionDate' | 'downPayment' | 'numberOfInstallments' | 'installmentFrequency' | 'installmentAmount' | 'paymentSchedule'>
      )> }
    ) }
  ) | (
    { __typename: 'RefundFullPaySuccess' }
    & { refundedSequence: Array<(
      { __typename?: 'RefundableSequenceItem' }
      & Pick<RefundableSequenceItem, 'timestamp' | 'paymentIntentId' | 'invoiceId' | 'refundableAmount'>
    )> }
  ) | { __typename: 'RefundInstallmentSuccess' } | { __typename: 'WaiveSuccess' } | (
    { __typename: 'ChargeSuccess' }
    & { getInvoiceDeciderResult: (
      { __typename?: 'GetInvoiceSuccess' }
      & Pick<GetInvoiceSuccess, 'isPaid' | 'isExpired' | 'policyFoxdenId' | 'timeZone'>
      & { invoiceInfo: (
        { __typename?: 'InvoiceInfo' }
        & Pick<InvoiceInfo, 'invoiceNumber' | 'invoiceDate' | 'quoteNumber' | 'totalPremium' | 'tax' | 'policyFee' | 'currency'>
      ) }
    ), userPaymentPlanOptionDeciderResult: (
      { __typename?: 'GetUserPaymentOptionSuccess' }
      & { userPaymentPlanOption: Array<(
        { __typename?: 'UserPaymentPlanOption' }
        & Pick<UserPaymentPlanOption, 'paymentPlanId' | 'paymentPlan' | 'invoiceNumber' | 'invoiceDate' | 'quoteNumber' | 'totalPremiumAmount' | 'totalSurcharge' | 'totalTaxes' | 'totalPolicyFees' | 'totalServiceFees' | 'totalPayable' | 'policyInceptionDate' | 'downPayment' | 'numberOfInstallments' | 'installmentFrequency' | 'installmentAmount' | 'paymentSchedule'>
      )> }
    ) }
  ) | { __typename: 'CancellationSuccess' } | (
    { __typename: 'FoxdenError' }
    & Pick<FoxdenError, 'fileName' | 'message'>
  ) }
);

export type IsInvoiceNumberRegisteredQueryVariables = Exact<{
  invoiceNumber: Scalars['String'];
}>;


export type IsInvoiceNumberRegisteredQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'isInvoiceNumberRegistered'>
);

export type GenerateSigningUrlMutationVariables = Exact<{
  quoteId: Scalars['ObjectID'];
}>;


export type GenerateSigningUrlMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'generateSigningURL'>
);


export const GetEmailAddressByQuoteIdDocument = gql`
    query getEmailAddressByQuoteId($quoteId: ObjectID!) {
  getEmailAddressByQuoteId(quoteId: $quoteId)
}
    `;

/**
 * __useGetEmailAddressByQuoteIdQuery__
 *
 * To run a query within a React component, call `useGetEmailAddressByQuoteIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailAddressByQuoteIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailAddressByQuoteIdQuery({
 *   variables: {
 *      quoteId: // value for 'quoteId'
 *   },
 * });
 */
export function useGetEmailAddressByQuoteIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetEmailAddressByQuoteIdQuery, GetEmailAddressByQuoteIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetEmailAddressByQuoteIdQuery, GetEmailAddressByQuoteIdQueryVariables>(GetEmailAddressByQuoteIdDocument, options);
      }
export function useGetEmailAddressByQuoteIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEmailAddressByQuoteIdQuery, GetEmailAddressByQuoteIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetEmailAddressByQuoteIdQuery, GetEmailAddressByQuoteIdQueryVariables>(GetEmailAddressByQuoteIdDocument, options);
        }
export type GetEmailAddressByQuoteIdQueryHookResult = ReturnType<typeof useGetEmailAddressByQuoteIdQuery>;
export type GetEmailAddressByQuoteIdLazyQueryHookResult = ReturnType<typeof useGetEmailAddressByQuoteIdLazyQuery>;
export type GetEmailAddressByQuoteIdQueryResult = ApolloReactCommon.QueryResult<GetEmailAddressByQuoteIdQuery, GetEmailAddressByQuoteIdQueryVariables>;
export const CreatePaymentSessionDocument = gql`
    mutation createPaymentSession($paymentInfo: CreatePaymentSessionInput!) {
  createPaymentSession(paymentInfo: $paymentInfo) {
    ... on MonerisData {
      hpp_id
      ticket
    }
    ... on StripeData {
      session_id
      stripePlatformAccount
    }
  }
}
    `;
export type CreatePaymentSessionMutationFn = ApolloReactCommon.MutationFunction<CreatePaymentSessionMutation, CreatePaymentSessionMutationVariables>;

/**
 * __useCreatePaymentSessionMutation__
 *
 * To run a mutation, you first call `useCreatePaymentSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentSessionMutation, { data, loading, error }] = useCreatePaymentSessionMutation({
 *   variables: {
 *      paymentInfo: // value for 'paymentInfo'
 *   },
 * });
 */
export function useCreatePaymentSessionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePaymentSessionMutation, CreatePaymentSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreatePaymentSessionMutation, CreatePaymentSessionMutationVariables>(CreatePaymentSessionDocument, options);
      }
export type CreatePaymentSessionMutationHookResult = ReturnType<typeof useCreatePaymentSessionMutation>;
export type CreatePaymentSessionMutationResult = ApolloReactCommon.MutationResult<CreatePaymentSessionMutation>;
export type CreatePaymentSessionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePaymentSessionMutation, CreatePaymentSessionMutationVariables>;
export const GetApplicationbyQuoteIdDocument = gql`
    query getApplicationbyQuoteId($quoteId: ObjectID!) {
  getApplicationbyQuoteId(quoteId: $quoteId) {
    country
    state
  }
}
    `;

/**
 * __useGetApplicationbyQuoteIdQuery__
 *
 * To run a query within a React component, call `useGetApplicationbyQuoteIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationbyQuoteIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationbyQuoteIdQuery({
 *   variables: {
 *      quoteId: // value for 'quoteId'
 *   },
 * });
 */
export function useGetApplicationbyQuoteIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetApplicationbyQuoteIdQuery, GetApplicationbyQuoteIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetApplicationbyQuoteIdQuery, GetApplicationbyQuoteIdQueryVariables>(GetApplicationbyQuoteIdDocument, options);
      }
export function useGetApplicationbyQuoteIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApplicationbyQuoteIdQuery, GetApplicationbyQuoteIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetApplicationbyQuoteIdQuery, GetApplicationbyQuoteIdQueryVariables>(GetApplicationbyQuoteIdDocument, options);
        }
export type GetApplicationbyQuoteIdQueryHookResult = ReturnType<typeof useGetApplicationbyQuoteIdQuery>;
export type GetApplicationbyQuoteIdLazyQueryHookResult = ReturnType<typeof useGetApplicationbyQuoteIdLazyQuery>;
export type GetApplicationbyQuoteIdQueryResult = ApolloReactCommon.QueryResult<GetApplicationbyQuoteIdQuery, GetApplicationbyQuoteIdQueryVariables>;
export const GetDocumentInformationDocument = gql`
    query getDocumentInformation($policyId: String!) {
  getDocumentInformation(policyId: $policyId) {
    documentDownloadLink {
      COI
      PaymentReceipt
      T_CDocument
      policyDocument
    }
    policyDetails {
      namedInsured
      operations
      policyEndDate
      policyStartDate
      timeZone
      country
      insuredLocation {
        city
        postalCode
        province
        street
      }
      mailingAddress {
        city
        postalCode
        province
        street
      }
    }
  }
}
    `;

/**
 * __useGetDocumentInformationQuery__
 *
 * To run a query within a React component, call `useGetDocumentInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentInformationQuery({
 *   variables: {
 *      policyId: // value for 'policyId'
 *   },
 * });
 */
export function useGetDocumentInformationQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetDocumentInformationQuery, GetDocumentInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetDocumentInformationQuery, GetDocumentInformationQueryVariables>(GetDocumentInformationDocument, options);
      }
export function useGetDocumentInformationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDocumentInformationQuery, GetDocumentInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetDocumentInformationQuery, GetDocumentInformationQueryVariables>(GetDocumentInformationDocument, options);
        }
export type GetDocumentInformationQueryHookResult = ReturnType<typeof useGetDocumentInformationQuery>;
export type GetDocumentInformationLazyQueryHookResult = ReturnType<typeof useGetDocumentInformationLazyQuery>;
export type GetDocumentInformationQueryResult = ApolloReactCommon.QueryResult<GetDocumentInformationQuery, GetDocumentInformationQueryVariables>;
export const GetAuth0UserPoliciesDocument = gql`
    query getAuth0UserPolicies {
  getAuth0UserPolicies
}
    `;

/**
 * __useGetAuth0UserPoliciesQuery__
 *
 * To run a query within a React component, call `useGetAuth0UserPoliciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuth0UserPoliciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuth0UserPoliciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAuth0UserPoliciesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAuth0UserPoliciesQuery, GetAuth0UserPoliciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetAuth0UserPoliciesQuery, GetAuth0UserPoliciesQueryVariables>(GetAuth0UserPoliciesDocument, options);
      }
export function useGetAuth0UserPoliciesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAuth0UserPoliciesQuery, GetAuth0UserPoliciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetAuth0UserPoliciesQuery, GetAuth0UserPoliciesQueryVariables>(GetAuth0UserPoliciesDocument, options);
        }
export type GetAuth0UserPoliciesQueryHookResult = ReturnType<typeof useGetAuth0UserPoliciesQuery>;
export type GetAuth0UserPoliciesLazyQueryHookResult = ReturnType<typeof useGetAuth0UserPoliciesLazyQuery>;
export type GetAuth0UserPoliciesQueryResult = ApolloReactCommon.QueryResult<GetAuth0UserPoliciesQuery, GetAuth0UserPoliciesQueryVariables>;
export const GetPaymentPlanOptionDocument = gql`
    query getPaymentPlanOption($InvoiceId: ObjectID!) {
  getUserPaymentPlanOption(InvoiceId: $InvoiceId) {
    paymentPlanId
    paymentPlan
    invoiceNumber
    invoiceDate
    quoteNumber
    totalPremiumAmount
    totalSurcharge
    totalTaxes
    totalPolicyFees
    totalServiceFees
    totalPayable
    policyInceptionDate
    downPayment
    numberOfInstallments
    installmentFrequency
    installmentAmount
    paymentSchedule
  }
}
    `;

/**
 * __useGetPaymentPlanOptionQuery__
 *
 * To run a query within a React component, call `useGetPaymentPlanOptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentPlanOptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentPlanOptionQuery({
 *   variables: {
 *      InvoiceId: // value for 'InvoiceId'
 *   },
 * });
 */
export function useGetPaymentPlanOptionQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetPaymentPlanOptionQuery, GetPaymentPlanOptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetPaymentPlanOptionQuery, GetPaymentPlanOptionQueryVariables>(GetPaymentPlanOptionDocument, options);
      }
export function useGetPaymentPlanOptionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPaymentPlanOptionQuery, GetPaymentPlanOptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetPaymentPlanOptionQuery, GetPaymentPlanOptionQueryVariables>(GetPaymentPlanOptionDocument, options);
        }
export type GetPaymentPlanOptionQueryHookResult = ReturnType<typeof useGetPaymentPlanOptionQuery>;
export type GetPaymentPlanOptionLazyQueryHookResult = ReturnType<typeof useGetPaymentPlanOptionLazyQuery>;
export type GetPaymentPlanOptionQueryResult = ApolloReactCommon.QueryResult<GetPaymentPlanOptionQuery, GetPaymentPlanOptionQueryVariables>;
export const MonerisCreatePaymentDocument = gql`
    mutation monerisCreatePayment($paymentInfo: CreatePaymentInput!) {
  monerisCreatePayment(paymentInfo: $paymentInfo) {
    hpp_id
    ticket
  }
}
    `;
export type MonerisCreatePaymentMutationFn = ApolloReactCommon.MutationFunction<MonerisCreatePaymentMutation, MonerisCreatePaymentMutationVariables>;

/**
 * __useMonerisCreatePaymentMutation__
 *
 * To run a mutation, you first call `useMonerisCreatePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMonerisCreatePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [monerisCreatePaymentMutation, { data, loading, error }] = useMonerisCreatePaymentMutation({
 *   variables: {
 *      paymentInfo: // value for 'paymentInfo'
 *   },
 * });
 */
export function useMonerisCreatePaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MonerisCreatePaymentMutation, MonerisCreatePaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<MonerisCreatePaymentMutation, MonerisCreatePaymentMutationVariables>(MonerisCreatePaymentDocument, options);
      }
export type MonerisCreatePaymentMutationHookResult = ReturnType<typeof useMonerisCreatePaymentMutation>;
export type MonerisCreatePaymentMutationResult = ApolloReactCommon.MutationResult<MonerisCreatePaymentMutation>;
export type MonerisCreatePaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<MonerisCreatePaymentMutation, MonerisCreatePaymentMutationVariables>;
export const StoreInvoiceInfoDocument = gql`
    mutation storeInvoiceInfo($invoice: InvoiceInfoInput!) {
  storeInvoiceInfo(invoice: $invoice)
}
    `;
export type StoreInvoiceInfoMutationFn = ApolloReactCommon.MutationFunction<StoreInvoiceInfoMutation, StoreInvoiceInfoMutationVariables>;

/**
 * __useStoreInvoiceInfoMutation__
 *
 * To run a mutation, you first call `useStoreInvoiceInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoreInvoiceInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storeInvoiceInfoMutation, { data, loading, error }] = useStoreInvoiceInfoMutation({
 *   variables: {
 *      invoice: // value for 'invoice'
 *   },
 * });
 */
export function useStoreInvoiceInfoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StoreInvoiceInfoMutation, StoreInvoiceInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<StoreInvoiceInfoMutation, StoreInvoiceInfoMutationVariables>(StoreInvoiceInfoDocument, options);
      }
export type StoreInvoiceInfoMutationHookResult = ReturnType<typeof useStoreInvoiceInfoMutation>;
export type StoreInvoiceInfoMutationResult = ApolloReactCommon.MutationResult<StoreInvoiceInfoMutation>;
export type StoreInvoiceInfoMutationOptions = ApolloReactCommon.BaseMutationOptions<StoreInvoiceInfoMutation, StoreInvoiceInfoMutationVariables>;
export const SendCoiDocument = gql`
    mutation sendCOI($additionalInsured: CoiAdditionalInsuredInput!, $policyFoxdenId: String!, $country: String!) {
  sendCOI(
    additionalInsured: $additionalInsured
    country: $country
    policyFoxdenId: $policyFoxdenId
  )
}
    `;
export type SendCoiMutationFn = ApolloReactCommon.MutationFunction<SendCoiMutation, SendCoiMutationVariables>;

/**
 * __useSendCoiMutation__
 *
 * To run a mutation, you first call `useSendCoiMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendCoiMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendCoiMutation, { data, loading, error }] = useSendCoiMutation({
 *   variables: {
 *      additionalInsured: // value for 'additionalInsured'
 *      policyFoxdenId: // value for 'policyFoxdenId'
 *      country: // value for 'country'
 *   },
 * });
 */
export function useSendCoiMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendCoiMutation, SendCoiMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SendCoiMutation, SendCoiMutationVariables>(SendCoiDocument, options);
      }
export type SendCoiMutationHookResult = ReturnType<typeof useSendCoiMutation>;
export type SendCoiMutationResult = ApolloReactCommon.MutationResult<SendCoiMutation>;
export type SendCoiMutationOptions = ApolloReactCommon.BaseMutationOptions<SendCoiMutation, SendCoiMutationVariables>;
export const GetPaymentStatusDocument = gql`
    query getPaymentStatus($invoiceId: ObjectID!, $placeholder: JSONObject) {
  getInvoice(invoiceId: $invoiceId, placeholder: $placeholder) {
    __typename
    ... on FoxdenError {
      fileName
      message
    }
    ... on GetInvoiceSuccess {
      isPaid
      policyFoxdenId
      invoiceInfo {
        client {
          address {
            city
            postalCode
            street
            region
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetPaymentStatusQuery__
 *
 * To run a query within a React component, call `useGetPaymentStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentStatusQuery({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *      placeholder: // value for 'placeholder'
 *   },
 * });
 */
export function useGetPaymentStatusQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetPaymentStatusQuery, GetPaymentStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetPaymentStatusQuery, GetPaymentStatusQueryVariables>(GetPaymentStatusDocument, options);
      }
export function useGetPaymentStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPaymentStatusQuery, GetPaymentStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetPaymentStatusQuery, GetPaymentStatusQueryVariables>(GetPaymentStatusDocument, options);
        }
export type GetPaymentStatusQueryHookResult = ReturnType<typeof useGetPaymentStatusQuery>;
export type GetPaymentStatusLazyQueryHookResult = ReturnType<typeof useGetPaymentStatusLazyQuery>;
export type GetPaymentStatusQueryResult = ApolloReactCommon.QueryResult<GetPaymentStatusQuery, GetPaymentStatusQueryVariables>;
export const InitiatePaymentGetInvoiceDocument = gql`
    query initiatePaymentGetInvoice($invoiceId: ObjectID!) {
  getInvoice(invoiceId: $invoiceId) {
    __typename
    ... on GetInvoiceSuccess {
      isExpired
      invoiceInfo {
        invoiceDate
        invoiceNumber
        quoteNumber
        tax
        totalPremium
        policyFee
        currency
        client {
          address {
            city
            postalCode
            street
            region
          }
        }
      }
      isPaid
      policyFoxdenId
    }
    ... on FoxdenError {
      fileName
      message
    }
  }
}
    `;

/**
 * __useInitiatePaymentGetInvoiceQuery__
 *
 * To run a query within a React component, call `useInitiatePaymentGetInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useInitiatePaymentGetInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInitiatePaymentGetInvoiceQuery({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useInitiatePaymentGetInvoiceQuery(baseOptions: ApolloReactHooks.QueryHookOptions<InitiatePaymentGetInvoiceQuery, InitiatePaymentGetInvoiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<InitiatePaymentGetInvoiceQuery, InitiatePaymentGetInvoiceQueryVariables>(InitiatePaymentGetInvoiceDocument, options);
      }
export function useInitiatePaymentGetInvoiceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InitiatePaymentGetInvoiceQuery, InitiatePaymentGetInvoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<InitiatePaymentGetInvoiceQuery, InitiatePaymentGetInvoiceQueryVariables>(InitiatePaymentGetInvoiceDocument, options);
        }
export type InitiatePaymentGetInvoiceQueryHookResult = ReturnType<typeof useInitiatePaymentGetInvoiceQuery>;
export type InitiatePaymentGetInvoiceLazyQueryHookResult = ReturnType<typeof useInitiatePaymentGetInvoiceLazyQuery>;
export type InitiatePaymentGetInvoiceQueryResult = ApolloReactCommon.QueryResult<InitiatePaymentGetInvoiceQuery, InitiatePaymentGetInvoiceQueryVariables>;
export const GetPaymentRouteDeciderDocument = gql`
    query getPaymentRouteDecider($invoiceId: ObjectID!) {
  paymentRouteDecider(invoiceId: $invoiceId) {
    __typename
    ... on FoxdenError {
      fileName
      message
    }
    ... on IsPaid {
      policyNumber
    }
    ... on IsExpired {
      isExpired
    }
    ... on InitialSuccess {
      getInvoiceDeciderResult {
        invoiceInfo {
          invoiceNumber
          invoiceDate
          quoteNumber
          totalPremium
          tax
          policyFee
          currency
          client {
            address {
              city
              postalCode
              street
              region
            }
          }
        }
        isPaid
        isExpired
        policyFoxdenId
        timeZone
      }
      userPaymentPlanOptionDeciderResult {
        userPaymentPlanOption {
          paymentPlanId
          paymentPlan
          invoiceNumber
          invoiceDate
          quoteNumber
          totalPremiumAmount
          totalSurcharge
          totalTaxes
          totalPolicyFees
          totalServiceFees
          totalPayable
          policyInceptionDate
          downPayment
          numberOfInstallments
          installmentFrequency
          installmentAmount
          paymentSchedule
        }
      }
    }
    ... on ChargeSuccess {
      getInvoiceDeciderResult {
        invoiceInfo {
          invoiceNumber
          invoiceDate
          quoteNumber
          totalPremium
          tax
          policyFee
          currency
        }
        isPaid
        isExpired
        policyFoxdenId
        timeZone
      }
      userPaymentPlanOptionDeciderResult {
        userPaymentPlanOption {
          paymentPlanId
          paymentPlan
          invoiceNumber
          invoiceDate
          quoteNumber
          totalPremiumAmount
          totalSurcharge
          totalTaxes
          totalPolicyFees
          totalServiceFees
          totalPayable
          policyInceptionDate
          downPayment
          numberOfInstallments
          installmentFrequency
          installmentAmount
          paymentSchedule
        }
      }
    }
    ... on RefundFullPaySuccess {
      refundedSequence {
        timestamp
        paymentIntentId
        invoiceId
        refundableAmount
      }
    }
  }
}
    `;

/**
 * __useGetPaymentRouteDeciderQuery__
 *
 * To run a query within a React component, call `useGetPaymentRouteDeciderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentRouteDeciderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentRouteDeciderQuery({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useGetPaymentRouteDeciderQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetPaymentRouteDeciderQuery, GetPaymentRouteDeciderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetPaymentRouteDeciderQuery, GetPaymentRouteDeciderQueryVariables>(GetPaymentRouteDeciderDocument, options);
      }
export function useGetPaymentRouteDeciderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPaymentRouteDeciderQuery, GetPaymentRouteDeciderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetPaymentRouteDeciderQuery, GetPaymentRouteDeciderQueryVariables>(GetPaymentRouteDeciderDocument, options);
        }
export type GetPaymentRouteDeciderQueryHookResult = ReturnType<typeof useGetPaymentRouteDeciderQuery>;
export type GetPaymentRouteDeciderLazyQueryHookResult = ReturnType<typeof useGetPaymentRouteDeciderLazyQuery>;
export type GetPaymentRouteDeciderQueryResult = ApolloReactCommon.QueryResult<GetPaymentRouteDeciderQuery, GetPaymentRouteDeciderQueryVariables>;
export const IsInvoiceNumberRegisteredDocument = gql`
    query isInvoiceNumberRegistered($invoiceNumber: String!) {
  isInvoiceNumberRegistered(invoiceNumber: $invoiceNumber)
}
    `;

/**
 * __useIsInvoiceNumberRegisteredQuery__
 *
 * To run a query within a React component, call `useIsInvoiceNumberRegisteredQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsInvoiceNumberRegisteredQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsInvoiceNumberRegisteredQuery({
 *   variables: {
 *      invoiceNumber: // value for 'invoiceNumber'
 *   },
 * });
 */
export function useIsInvoiceNumberRegisteredQuery(baseOptions: ApolloReactHooks.QueryHookOptions<IsInvoiceNumberRegisteredQuery, IsInvoiceNumberRegisteredQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<IsInvoiceNumberRegisteredQuery, IsInvoiceNumberRegisteredQueryVariables>(IsInvoiceNumberRegisteredDocument, options);
      }
export function useIsInvoiceNumberRegisteredLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsInvoiceNumberRegisteredQuery, IsInvoiceNumberRegisteredQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<IsInvoiceNumberRegisteredQuery, IsInvoiceNumberRegisteredQueryVariables>(IsInvoiceNumberRegisteredDocument, options);
        }
export type IsInvoiceNumberRegisteredQueryHookResult = ReturnType<typeof useIsInvoiceNumberRegisteredQuery>;
export type IsInvoiceNumberRegisteredLazyQueryHookResult = ReturnType<typeof useIsInvoiceNumberRegisteredLazyQuery>;
export type IsInvoiceNumberRegisteredQueryResult = ApolloReactCommon.QueryResult<IsInvoiceNumberRegisteredQuery, IsInvoiceNumberRegisteredQueryVariables>;
export const GenerateSigningUrlDocument = gql`
    mutation generateSigningURL($quoteId: ObjectID!) {
  generateSigningURL(quoteId: $quoteId)
}
    `;
export type GenerateSigningUrlMutationFn = ApolloReactCommon.MutationFunction<GenerateSigningUrlMutation, GenerateSigningUrlMutationVariables>;

/**
 * __useGenerateSigningUrlMutation__
 *
 * To run a mutation, you first call `useGenerateSigningUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateSigningUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateSigningUrlMutation, { data, loading, error }] = useGenerateSigningUrlMutation({
 *   variables: {
 *      quoteId: // value for 'quoteId'
 *   },
 * });
 */
export function useGenerateSigningUrlMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GenerateSigningUrlMutation, GenerateSigningUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<GenerateSigningUrlMutation, GenerateSigningUrlMutationVariables>(GenerateSigningUrlDocument, options);
      }
export type GenerateSigningUrlMutationHookResult = ReturnType<typeof useGenerateSigningUrlMutation>;
export type GenerateSigningUrlMutationResult = ApolloReactCommon.MutationResult<GenerateSigningUrlMutation>;
export type GenerateSigningUrlMutationOptions = ApolloReactCommon.BaseMutationOptions<GenerateSigningUrlMutation, GenerateSigningUrlMutationVariables>;